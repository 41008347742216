<template>
  <section class="mt-4">
    <PageMetadata />
    <modal
      v-if="deleteProductModalShow"
      :closeFn="hideDeleteProductModal"
      closeBtn="cancel"
      :submitFn="voidOrder"
      submitBtn="ok"
    >
      Are you sure, you want to void Order?
    </modal>
    <loading-overlay
      :active="isLoading"
      :is-full-page="fullPage"
      :loader="loader"
    />
    <flash-alert v-model:show="flashAlert.show" :level="flashAlert.level">
      <span v-html="flashAlert.msg"></span>
    </flash-alert>
    <div id="account-profile" class="container">
      <div class="row">
        <h1 class="text-center">{{ getMetaDetails.page_h1 }}</h1>
        <div
          class="text-center"
          v-if="!!getMetaDetails.description"
          v-html="getMetaDetails.description"
        ></div>
      </div>

      <div class="row mb-5">
        <div class="col-md-5">
          <div class="md-form">
            <label for="event_date" class="">From</label>
            <v-date-picker
              v-model="fromDate"
              :first-day-of-week="1"
              color="green"
              range="false"
              :masks="{ input: 'MM-DD-YYYY' }"
              trim-weeks
            >
              <template v-slot="{ inputValue, togglePopover }">
                <div @click="togglePopover()" class="input-group flex-nowrap">
                  <input :value="inputValue" class="form-control" />
                  <span class="input-group-text" id="basic-addon2"
                    ><i class="fa-solid fa-calendar-days"></i
                  ></span>
                </div>
              </template>
            </v-date-picker>
          </div>
        </div>

        <div class="col-md-5">
          <div class="md-form">
            <label for="event_date" class="">To</label>
            <v-date-picker
              v-model="toDate"
              :first-day-of-week="1"
              color="green"
              range="false"
              :masks="{ input: 'MM-DD-YYYY' }"
              trim-weeks
            >
              <template v-slot="{ inputValue, togglePopover }">
                <div @click="togglePopover()" class="input-group flex-nowrap">
                  <input :value="inputValue" class="form-control" />
                  <span class="input-group-text" id="basic-addon2"
                    ><i class="fa-solid fa-calendar-days"></i
                  ></span>
                </div>
              </template>
            </v-date-picker>
            <div v-if="errorDateRange" class="text-danger">
              Please select date range betweeen 170 days.
            </div>
          </div>
        </div>

        <div class="col-md-2 buttons">
          <button
            type="button"
            @click="fetchHistory()"
            class="btn btn-primary w-100"
            style="margin-top: 21px"
          >
            View
          </button>
        </div>
      </div>

      <div class="table-responsive">
        <table
          v-if="invoicesCount > 0"
          class="table table-striped table-bordered text-center"
        >
          <thead class="bg-grey">
            <tr>
              <th>Invoice #</th>
              <th>Invoice Date</th>
              <th>PO #</th>
              <th>Order Ref</th>
              <th>City</th>
              <th>State</th>
              <th>Status</th>
              <th>Total</th>
              <th>View</th>
              <th>PDF</th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(invoice, index) in invoices" :key="index">
              <td>
                <a
                  v-if="invoice.totalBoxes"
                  :href="`/account/order-details/${invoice.id}`"
                  >{{
                    invoice.ecommerce ? "W" + invoice.number : invoice.number
                  }}</a
                >
                <span v-else>{{ invoice.number }}</span>
              </td>
              <td>{{ formatDateWithDash(invoice.shipDate) }}</td>
              <td>{{ invoice.po }}</td>
              <td>{{ invoice.orderRef }}</td>
              <td>{{ invoice.shipCity }}</td>
              <td>{{ invoice.shipState }}</td>
              <td>{{ invoice.status }}</td>
              <td>${{ invoice.total }}</td>
              <td>
                <div class="d-inline-flex">
                  <Popper :arrow="true" :hover="true" content="View">
                    <a
                      v-if="invoice.totalBoxes"
                      :href="`/account/order-details/${invoice.id}`"
                      ><i class="fa-regular fa-file-lines" aria-hidden="true"></i
                    ></a>
                  </Popper>
                </div>
              </td>
              <td>
                <div class="d-inline-flex">
                  <Popper :arrow="true" :hover="true" content="Download PDF">
                    <a
                      href="#"
                      @click.prevent="downloadPdf(invoice.id, invoice.number)"
                      ><i class="fa-solid fa-file-pdf" aria-hidden="true"></i
                    ></a>
                  </Popper>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </section>
</template>

<script>
import { misc } from "@/mixins";
import axios from "axios";
import { FlashAlert, PageMetadata, Modal } from "../components";
import Popper from "vue3-popper";

export default {
  name: "InvoiceHistory",
  mixins: [misc],
  components: { FlashAlert, PageMetadata, Modal, Popper },
  data() {
    return {
      loader: "bars",
      isLoading: false,
      deleteProductModalShow: false,
      fullPage: true,
      order_id: this.$route.params.order_id,
      orderInvoiceDetails: {},
      invoicesCount: 0,
      errorDateRange: false,
      toDate: new Date(),
      fromDate: new Date(),
      invoices: [],
    };
  },
  mounted() {
    localStorage.setItem("currentPage", "invoice-history");
    this.fetchMetaDetails();
    this.emitter.on("showLoader", () => {
      this.showLoader();
    });

    this.toDate.setDate(this.toDate.getDate() + 8);
    this.fromDate.setDate(this.toDate.getDate() - 170);
    this.fromDate = this.formatDate(this.fromDate);
    this.toDate = this.formatDate(this.toDate);
    this.emitter.on("deletedProduct", () => {
      this.isLoading = false;
    });
    this.fetchHistory();
  },

  methods: {
    showLoader() {
      this.isLoading = true;
    },
    fetchHistory() {
      this.errorDateRange = false;
      let date1 = new Date(this.toDate);
      let date2 = new Date(this.fromDate);
      let Difference_In_Time = date1.getTime() - date2.getTime();
      let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
      if (Difference_In_Days > 170) {
        this.errorDateRange = true;
        this.showFlashAlert("Date range should be less than 170 days", "error");
        return false;
      }

      this.showLoader();
      try {
        axios
          .post(
            process.env.VUE_APP_API_BASE + "auth/getOrders",
            {
              date_from: this.formatDateFromStr(this.fromDate),
              date_to: this.formatDateFromStr(this.toDate),
            },
            {
              headers: {
                "Content-type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            if (response.data.error == true) {
              this.invoices = {};
              this.showFlashAlert(response.data.message, "error");
              this.isLoading = false;
            } else {
              this.invoices = response.data.invoices;
              this.invoicesCount = this.ObjectLength_Modern(this.invoices);
              this.isLoading = false;
            }
          });
      } catch (ex) {
        console.log("Catch is here", ex);
        this.isLoading = false;
      }
    },

    ObjectLength_Modern(object) {
      return Object.keys(object).length;
    },

    downloadPdf(orderId, invoiceNumber) {
      this.isLoading = true;

      axios
        .post(
          `${process.env.VUE_APP_API_BASE}auth/getOrderPdf`,
          {
            order_id: orderId,
          },
          {
            headers: {
              "Content-type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
            responseType: "arraybuffer",
            dataType: "blob",
          }
        )
        .then((responseBlob) => {
          if (responseBlob.headers['content-type'] === 'application/json'){
            let response = JSON.parse(String.fromCharCode.apply(null, new Uint8Array(responseBlob.data)));
            if (response.error) {
              let message = response.message;
              let payload = { message: message, level: 'error' };
              this.emitter.emit("displayFlash", payload);
            }
          } else {
            const file = new Blob([responseBlob.data], { type: "application/pdf" });
            const fileURL = URL.createObjectURL(file);
            let fileName = "invoice #" + invoiceNumber;
            const link = document.createElement("a");
            link.href = fileURL;
            link.download = `${fileName}.pdf`;
            link.click();
          }
          this.isLoading = false;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped>
.font-weight-bold {
  font-weight: bold;
}
.vld-overlay .vld-background {
  background: #000 !important;
  opacity: 0.5;
}
a {
  text-decoration: none;
}
</style>
