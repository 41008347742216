<template>
  <!-- Modal -->
  <div class="modal fade show" style="display: block" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog " :class="position == 'center' ? 'modal-dialog-centered' : ''">
      <div class="modal-content">
        <div class="modal-header" v-if="!requestSent">
          <h5 class="modal-title" id="exampleModalLabel">Request a Product</h5>
          <button type="button" class="btn-close" @click="closeModal" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="product-card" v-if="!requestSent">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <div class=" no-gutters">
                    <div class="row align-items-center text-center text-lg-start">
                      <h5 id="delivery-date-label" class="col-lg pe-lg-0 col-12 bolder-size mb-3 mb-lg-0 ps-0">DELIVERY DATE</h5>
                      <div class="col-lg-auto col-12">
                        <div class="input-group d-md-inline-flex align-items-center delivery-date-row">
                          <div class="calender_container mx-auto">
                            <delivery-date-picker v-model:dateProp="actualDeliveryDate" 
                              :minDate="minDate" :maxDate="maxDate" :disabledDates="disabledDates" pageKey="shpDeliveryDate" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row no-gutters">
                    <div class="col-auto product-img-wrap position-relative no-overflow">
                      <Popper v-if="product.highlight_name" :arrow="true" :hover="true" :content="`${product.highlight_name}`">
                        <div class="ribbon2">
                          <span
                            :style="`border-color: ${product.highlight_hex_code}; background: ${product.highlight_hex_code}; background: linear-gradient(${ product.highlight_hex_code } 0%, ${ product.highlight_hex_code } 100%);`">{{
                            product.highlight_name }}</span>
                        </div>
                      </Popper>
                      <router-link :to="`/${product.seo_url}`">
                        <img v-if="product.image" :src="`${imagePath}` + product.image" class="m-0 zoom" :alt="product.name" />
                        <img v-else :src="`${imagePath}` + getDefaultImage(product.product_type_id)" class="m-0" :alt="product.name" />
                      </router-link>
                      <!-- <add-to-wishlist :prod="product" class="fr-wish"></add-to-wishlist> -->
                    </div>
                    <div class="col product-name pl-0 mb-4">
                      <div class="tool-tip">
                        <Popper class="d-inline ml-1 breakdown-popper" v-if="product.breakdown != null && product.breakdown.length > 2" arrow="true" hover="true">
                          <template #content>
                            <div v-if="product.programtype == 'prb' || product.programtype == 'lal'" 
                              v-html="getBreakdownForPulls(product.breakdown, product.box_unit_singular)"></div>
                            <div v-else v-html="getBreakdown(product.breakdown, product.box_unit_singular)"></div>
                          </template>
                          <span class="star-color"><i class="fa-solid fa-star text-warning" aria-hidden="true"></i>&nbsp;</span>
                        </Popper>
                        <span class="d-inline-block highlight-description" v-if="product.highlight_description">
                          <Popper :arrow="true" :hover="true" :content="product.highlight_description" style="margin:0; border: none;">
                            <i class="fa-solid fa-circle-info"></i>
                          </Popper>
                        </span>
                        <!-- <router-link :to="`/${product.seo_url}`">{{ product.name }}</router-link> -->
                        {{ product.name }}
                        <span v-if="product.country_id > 0">&nbsp;</span>
                        <span class="us_img_icon d-inline-block" v-if="product.country_id > 0">
                          <Popper :arrow="true" :hover="true" :content="product.country_name">
                            <img :src="`${imagePath}` + product.country_flag" />
                          </Popper>
                        </span>
                        <span v-if="product.state_id > 0">&nbsp;</span>
                        <span class="us_img_icon d-inline-block" v-if="product.state_id > 0">
                          <Popper :arrow="true" :hover="true" :content="product.state_name">
                            <img :src="`${imagePath}` + product.state_flag" />
                          </Popper>
                        </span>
                        <Popper v-if="product.special" :arrow="true" :hover="true" :content="`${product.special}% Off`">
                          <div class="ms-2 badge bg-peach">
                            <span>{{ product.special }}% Off</span>
                          </div>
                        </Popper>
                        <br />
                        <span><i>{{ product.farm_name }}<template v-if="product.is_k2k"> - </template></i></span>
                      </div>
                    </div>
                    <div class="w-100 d-block "></div>
                    <div class="col col-lg-auto lot-size px-0">
                      <div class="packing me-auto">
                        <label class="d-block">Available</label>
                        <span v-if="product.programtype == 'mdb' || product.programtype == 'lal' || product.programtype == 'fdb'"></span>
                        {{ product.qty > 1 ? product.box_name_plural : product.box_name_singular}}
                        <br />
                        <span v-if="product.box_name_singular != product.box_unit_singular">({{ product.unit_count }}
                          {{ product.unit_count > 1 ? product.box_unit_plural : product.box_unit_singular}})</span>
                      </div>
                    </div>
                    <div class="col col-lg-auto price px-0">
                      <div class="price-per-unit mx-auto">
                        <label class="d-block ">Price/Unit</label>
                        <strong>
                          ${{ $filters.numberFormat(product.price_per_stem) }} /
                          {{ product.box_unit_singular }}
                        </strong>
                      </div>
                    </div>
                    <div class="col col-lg-auto lot-price px-0">
                      <div class="price-per-box mx-auto">
                        <label class="d-block">Price</label>
                        ${{ $filters.numberFormat(product.price_per_unit) }} /
                        {{ product.box_name_singular }}
                      </div>
                    </div>
                    <div class="col-auto quantity px-0">
                      <div class="quantity-value ms-auto">
                        <label class="d-block">QTY</label>
                        <input type="number" v-model="quantity">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="text-end">
              <button type="button" class="btn-close" @click="closeModal" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="text-center w-100 d-flex flex-column justify-content-center align-items-center mx-auto" style="min-height: 200px; max-width: 80%; margin-bottom: 21px;">
              <h5 class="peach-color fw-bold mb-4">Request submitted<br>successfully!</h5>
              <p>One of our reps will respond within 48 business hours regarding availability.</p>
            </div>
          </div>
        </div>
        <div class="modal-footer" v-if="!requestSent">
          <div class="d-block w-100">
            <flash-alert v-model:show="flashAlert.show" :level="flashAlert.level">
              <span v-html="flashAlert.msg"></span>
            </flash-alert>
          </div>
          <input type="button" @click="submitModal" class="btn btn-primary" value="Submit" />
          <input type="button" @click="closeModal" class="btn btn-primary" value="Cancel" />
        </div>
      </div>
    </div>
  </div>

  <div class="modal-backdrop fade show"></div>
</template>

<script>
import axios from "axios";
import Popper from "vue3-popper";
import { misc } from "@/mixins";
// import AddToWishlist from "./AddToWishlist.vue";
import DeliveryDatePicker from "./DeliveryDatePicker.vue";
import FlashAlert from "./FlashAlert.vue";

export default {
  name: "FdbRequestProductForm",
  mixins: [misc],
  props: {
    closeFn: {
      type: Function,
      required: true,
    },
    closeBtn: {
      type: String,
      default: "OK",
    },
    product: {
      type: Object,
      required: true
    },
    minDate: {
      type: String,
      required: false,
    },
    maxDate: {
      type: String,
      required: false,
    },
    disabledDates: {
      type: Array,
      required: false,
    },
    deliveryDate: {
      type: String,
      required: true
    }
  },
  components: {
    Popper,
    // AddToWishlist,
    DeliveryDatePicker,
    FlashAlert
  },
  data() {
    return {
      product_name: this.product.name,
      quantity: 1,
      message: '',
      actualDeliveryDate: this.deliveryDate,
      requestSent: false
    };
  },
  methods: {
    closeModal() {
      this.closeFn();
    },
    submitModal() {
      this.isLoading = true;
      axios
        .post(process.env.VUE_APP_API_BASE + "auth/requestFdbProduct", 
        {
          product: this.product,
          quantity:this.quantity,
          delivery_date: this.actualDeliveryDate
          
        },
        {
            headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            },
        }).then((response) => {
          if (response.data.error) {
            this.triggerFlashAlert(response.data.message, "error");
          } else {
            this.requestSent = true;
            // this.emitter.emit("displayFlash", {
            //   message: response.data.message,
            //   level: "success",
            // });
            // this.closeFn();
          }
          this.emitter.emit("hideLoader");
          this.isLoading = false;
        }).catch((err) => {
          console.log('An error occurred while sending request. ', err);
          this.isLoading = false;
          this.closeFn();
        });
    },
    
  },
};
</script>
<style scoped>
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #9f9f9f;
  border-color: #9f9f9f;
}

.buttons .btn:hover,
.btn[type="button"]:hover {
  border: 2px solid #9f9f9f;
  background: #9f9f9f;
  color: #fff;
}
.buttons .btn,
.btn[type="button"]
{
  border: 2px solid #9f9f9f;
  border-radius: inherit;
  background: inherit;
  color: #797979;
  padding: 8px 0px;
  width: 90px !important;
}
.btn-primary:hover {
  color: #fff;
  background-color: #9f9f9f;
  border-color: #9f9f9f;
}
h4 {
  font-size: 1.2 rem;
}
thead {
  background: #efefef;
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
}
.modal-footer {
  justify-content: center !important;
}

.btn[type="button"] {
  border-radius: 0px;
}

span.cutoff_label {
    font-size: 14px !important;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    height: 38px;
}

.modal-dialog {
  max-width: 540px;
}

.modal-header, .modal-body, .modal-footer {
  padding-left: 20px;
  padding-right: 20px;
}

.product-name {
  padding-left: 18px !important;
}

.lot-size {
  border-left-width: 0px !important;
  max-width: 110px !important;
  width: 110px !important;
}

.packing {
  width: 86px !important;
}

.price {
  max-width: 149px !important;
  width: 149px !important;
}

.price-per-unit {
  width: 104px !important;
}

.lot-price {
  max-width: 146px !important;
  width: 146px !important;
}

.price-per-box {
  width: 98px !important;
}

.quantity {
  max-width: 93px !important;
}

.quantity-value {
  width: 70px !important;
}

@media (min-width: 992px) {
  .product-card .card-body .row > div[class*="col"]:not(:first-of-type) {
    align-items: normal;
  }
}
</style>