<template>
  <v-date-picker
    :key="key"
    v-model="dateModel"
    :first-day-of-week="1"
    color="green"
    :masks="{ input: 'MM-DD-YYYY' }"
    trim-weeks
    :available-dates="availableDates"
    :min-date="minDate"
    :max-date="maxDate"
    :disabled-dates="disabledDates"
  >
    <template v-slot="{ inputValue, togglePopover }">
      <div @click="togglePopover()" class="input-group flex-nowrap">
        <input :value="inputValue" class="form-control" />
        <span class="input-group-text" id="basic-addon2"><i class="fa-solid fa-calendar-days"></i></span>
      </div>
    </template>
  </v-date-picker>

  <modal
    v-if="onDateChange.warnModal.show"
    :closeFn="rejectDateChange"
    closeBtn="cancel"
    :submitFn="acceptDateChange"
    submitBtn="change dates"
    :checkoutFn="checkoutNow"
    checkoutBtn="Checkout Now"
    displayCheckoutBtn=true
  >
    By changing dates, you will lose the products that are in your cart, are
    you sure to go ahead?
  </modal>
</template>


<script>
import { mapState, mapActions } from "vuex";
import Modal from "./Modal";
import { misc } from "@/mixins";

export default {
  name: "DeliveryDatePicker",
  components: {
    Modal,
  },
  emits: ["update:dateProp", "deliveryDateChanged"],
  mixins: [misc],
  props: {
    availableDates: {
      type: Array,
      default() {
        return [];
      },
    },
    minDate: {
      type: String,
      default: "",
    },
    maxDate: {
      type: String,
      default: "",
    },
    disabledDates: {
      type: Array,
      default() {
        return [];
      },
    },
    dateProp: {
      type: String,
      default: "",
    },
    pageKey: {
      type: String,
      default: "",
    },
    shoppingListId: {
      type: Number,
      default: 0,
    },
    holHolidayId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      key: 0,
      onDateChange: {
        warnModal: {
          show: false,
        },
        oldDate: "",
        trigger: true,
      },
    };
  },
  computed: {
    ...mapState(["cartMeta"]),

    dateModel: {
      get: function () {
        return this.appendTimeForBrowser(this.dateProp);
      },
      set: function (n) {
        if (n) {
          if (typeof n.getMonth === "function") {
            n = this.formatToDb(n);
          }

          const oldVal = this.appendTimeForBrowser(this.dateModel);
          this.$emit("update:dateProp", n);
          if (this.pageKey == 'sblDeliveryDate') {
            if (this.cartMeta.count && this.cartMeta[this.pageKey] != null && this.cartMeta[this.pageKey][this.shoppingListId] != '' && this.cartMeta[this.pageKey][this.shoppingListId] != null) {
              if (this.onDateChange.trigger) {
                this.onDateChange.oldDate = oldVal;
                this.onDateChange.warnModal.show = true;
              }
              this.onDateChange.trigger = true;
            } else {
              this.processDateChange();
            }
          } else {
            if (this.pageKey == 'holDeliveryDate') {
              if (this.cartMeta.count && this.cartMeta[this.pageKey] != null && this.cartMeta[this.pageKey][this.holHolidayId] != '' && this.cartMeta[this.pageKey][this.holHolidayId] != null) {
                if (this.onDateChange.trigger) {
                  this.onDateChange.oldDate = oldVal;
                  this.onDateChange.warnModal.show = true;
                }
                this.onDateChange.trigger = true;
              } else {
                this.processDateChange();
              }
            } else {
              if (this.cartMeta.count && this.cartMeta[this.pageKey] != '' && this.cartMeta[this.pageKey] != null) {
                if (this.onDateChange.trigger) {
                  this.onDateChange.oldDate = oldVal;
                  this.onDateChange.warnModal.show = true;
                }
                this.onDateChange.trigger = true;
              } else {
                this.processDateChange();
              }
            }
          }
        } else {
          this.key++;
        }
      },
    },
  },
  methods: {
    ...mapActions(["clearCartApi","clearCartByProgram"]),

    hideDateModal() {
      this.onDateChange.warnModal.show = false;
    },
    rejectDateChange() {
      this.onDateChange.trigger = false;
      this.dateModel = this.onDateChange.oldDate;
      this.hideDateModal();
    },
    acceptDateChange() {
      if (this.pageKey == 'shpDeliveryDate') {
        localStorage.setItem('cartProgram','SHP');
      }
      if (this.pageKey == 'ddbDeliveryDate') {
        localStorage.setItem('cartProgram','DBL');
      }
      if (this.pageKey == 'holDeliveryDate') {
        localStorage.setItem('cartProgram','DBL2');
        localStorage.setItem('holHolidayId',this.holHolidayId);
      }
      if (this.pageKey == 'hgsDeliveryDate') {
        localStorage.setItem('cartProgram','HGS');
      }
      if (this.pageKey == 'mmtDeliveryDate') {
        localStorage.setItem('cartProgram','MMT');
      }
      if (this.pageKey == 'spsDeliveryDate') {
        localStorage.setItem('cartProgram','SPS');
      }
      if (this.pageKey == 'sblDeliveryDate') {
        localStorage.setItem('cartProgram','SBL');
        localStorage.setItem('shoppingListId',this.shoppingListId);
      }
      this.clearCartByProgram();
      this.processDateChange();
      this.hideDateModal();
    },
    processDateChange() {
      this.$emit("deliveryDateChanged", this.dateModel);
    },
    checkoutNow() {
      let types = this.getCurrentCartTypes;
      let list = this.getCurrentShoppingLists;
      let holHoliday = this.getCurrentHolHolidays;
      let query = [];
      if (types != '' && types != undefined && types != null) {
        query['type'] = types;
      }
      if (list != '' && list != undefined && list != null) {
        query['list'] = list;
      }
      if (holHoliday != '' && holHoliday != undefined && holHoliday != null) {
        query['hol_holiday'] = holHoliday;
      }

      this.$router.push({ name: "Checkout", query: query });
    },
  },
};
</script>