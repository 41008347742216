<!-- eslint-disable -->
<template>
  <div class="container">
    <nav class="navbar navbar-expand-sm navbar-light px-0 py-4">
      <ul class="navbar-nav flex-grow-1  d-md-flex" id="menu">
        <li 
          @mouseenter="megaMenu(true, 'mega-menu-'+idx, m.is_mega_menu)" 
          @mouseleave="megaMenu(false, 'mega-menu-'+idx, m.is_mega_menu)" 
          :id="`mega-menu-${ idx }`"
          class="nav-item " 
          v-for="(m, idx) in menu" :key="idx" :class="[idx == currentMenuIdx ? 'open' : '']"
        >
          <a
            class="nav-link d-none d-md-block d-lg-none"
            :class="{ 'dropdown-toggle': m.is_mega_menu }"
            :href="`javascript:void(0)`"
            target="_blank"
            v-if="m.blank == 1"
            >{{ m.title }}
          </a>
          <a
            class="nav-link d-none d-md-block d-lg-none"
            :class="{ 'dropdown-toggle': m.is_mega_menu }"
            :href="`javascript:void(0)`"
            v-else
            >{{ m.title }}
          </a>
          <a
            class="nav-link d-none d-lg-block"
            :class="{ 'dropdown-toggle': m.is_mega_menu }"
            :href="`${m.url}`"
            target="_blank"
            v-if="m.blank == 1"
            >{{ m.title }}
          </a>
          <a
            class="nav-link d-none d-lg-block"
            :class="{ 'dropdown-toggle': m.is_mega_menu }"
            :href="`/${m.url}`"
            v-else
            >{{ m.title }}
          </a>
          <a
            class="nav-link flex-grow-1 d-flex d-md-none"
            :class="[m.is_mega_menu == 1 ? 'dropdown-toggle': '' ]"
            :href="[m.is_mega_menu == 1 ? 'javascript:void(0)': `/${m.url}` ]"
            @click="showMegaMenu(idx)"
            :ref="setmegaMenuItemRef"
            >{{ m.title }}
            <i class="fa-solid fa-chevron-right" v-if="m.is_mega_menu == 1 "></i>
          </a>

          <template v-if="m.is_mega_menu">
            <div class="mega-menu" v-if="m.url === 'shop'">
              <div class="sub-menu d-none d-md-block">
                <h3 class="megamenu_container_strip">
                  MAYESH MARKET <i class="fa-solid fa-caret-right"></i>
                </h3>
              </div>
              <aside class="left-side">
                <div class="left-menu">
                  <div class="row flex-lg-nowrap gx-0 gx-md-5">
                    <div class="mb-md-4 col-md-6" v-if="shopMenu.programs.length > 0" v-bind:class="shopMenu.shoppingLists.length > 0 ? ' col-lg-3' : 'col-lg-4'">
                      <input class="invisible d-none" type="radio" id="shopByProg" value="shopByProg" v-model="selected" @click="uncheck('shopByProg')" name="shopByAccordion">
                      <h3 class="mega-menu-title">
                        <label class="d-flex flex-nowrap" for="shopByProg">
                          <template v-if="shopMenu.shoppingLists.length < 1">SHOP BY PROGRAM</template>
                          <template v-else>PROGRAMS</template>
                          <i class="fa-solid fa-plus"></i></label>
                      </h3>
                      <ul class="cat_container list-unstyled">
                        <li v-for="(program, index) in shopMenu.programs" :key="index" :ref="`${program.css_class}`">
                          <Popper v-if="program.tooltip_description != '' && program.tooltip_description != null" :arrow="true" :hover="true" :content="`${program.tooltip_description}`">
                            <a v-if="program.code == 'HGS'"
                            :ref="`${program.css_class}`"
                            :href="`/hardgoods?&from=mm`">{{
                              program.name
                            }}</a>
                            <a v-else-if="program.code == 'DBL'"
                            :ref="`${program.css_class}`"
                            :href="`/dutch-direct-boxlots?&from=mm`">{{
                              program.name
                            }}</a>
                            <a v-else-if="program.code == 'MMT'"
                            :ref="`${program.css_class}`"
                            :href="`/luxe-blooms-grocery?&from=mm`">{{
                              program.name
                            }}</a>
                            <a v-else-if="program.code == 'WSP'"
                            :ref="`${program.css_class}`"
                            :href="`/specials?&from=mm`">{{
                              program.name
                            }}</a>
                            <a v-else
                            :ref="`${program.css_class}`"
                            :href="`/shop?filter_program=${program.id}&filter_bt=1&from=mm`">{{
                              program.name
                            }}</a>
                          </Popper>
                          <template v-else>
                            <a v-if="program.code == 'HGS'"
                            :ref="`${program.css_class}`"
                            :href="`/hardgoods?&from=mm`">{{
                              program.name
                            }}</a>
                            <a v-else-if="program.code == 'DBL'"
                            :ref="`${program.css_class}`"
                            :href="`/dutch-direct-boxlots?&from=mm`">{{
                              program.name
                            }}</a>
                            <a v-else-if="program.code == 'MMT'"
                            :ref="`${program.css_class}`"
                            :href="`/luxe-blooms-grocery?&from=mm`">{{
                              program.name
                            }}</a>
                            <a v-else-if="program.code == 'WSP'"
                            :ref="`${program.css_class}`"
                            :href="`/specials?&from=mm`">{{
                              program.name
                            }}</a>
                            <a v-else
                            :ref="`${program.css_class}`"
                            :href="`/shop?filter_program=${program.id}&filter_bt=1&from=mm`">{{
                              program.name
                            }}</a>
                          </template>
                        </li>
                      </ul>
                    </div>
                    <div class="mb-md-4 col-md-6 col-lg-3 shop-by-list" v-if="shopMenu.shoppingLists.length > 0">
                      <input class="invisible d-none" type="radio" id="shoppingLists" value="shoppingLists" v-model="selected" @click="uncheck('shoppingLists')" name="shopByAccordion">
                      <h3 class="mega-menu-title">
                        <label class="d-flex flex-nowrap" for="shoppingLists">SPECIAL OFFERS
                        <i class="fa-solid fa-plus"></i></label>
                      </h3>
                      <ul class="cat_container list-unstyled">
                        <li v-for="(shoppingList, index) in shopMenu.shoppingLists" :key="index">
                          <a v-if="shoppingList.is_hol_holiday" :href="`/${shoppingList.slug}?from=mm`" class="more-link">{{ shoppingList.name }}</a>
                          <a v-else :href="`/special-offers/${shoppingList.slug}?from=mm`" class="more-link">{{ shoppingList.name }}</a>
                        </li>
                        <li class="mt-4"><p class="megamen_verbage no-letter-spacing"><a href="/special-offers?from=mm">View all</a></p></li>
                      </ul>
                    </div>
                    <div class="mb-md-4 col-md-6 col-lg-3 shop-by-color">
                      <input class="invisible d-none" type="radio" id="shopByColor" value="shopByColor" v-model="selected" @click="uncheck('shopByColor')" name="shopByAccordion">
                      <h3 class="mega-menu-title">
                        <label class="d-flex flex-nowrap" for="shopByColor">
                          <template v-if="shopMenu.colorBubbles.length > 0">
                            <template v-if="shopMenu.shoppingLists.length < 1">SHOP BY COLOR</template>  
                            <template v-else>COLORS</template>
                          </template>
                          <i class="fa-solid fa-plus"></i></label>
                      </h3>
                      <div class="cat_container">
                        <div class="filter-color-group">
                          <!-- Filter Color Item -->
                          <div
                            class="filter-color-item"
                            v-for="(
                              colorBubble, index
                            ) in shopMenu.colorBubbles"
                            :key="index"
                          >
                            <div class="d-inline-flex" style="text-transform: none !important;">
                              <Popper :arrow="true" :hover="true" :content="`${colorBubble.color}`">
                                <label class="filter-color-group__link">
                                  <a
                                    v-if="colorBubble.color_image != '' && colorBubble.color_image !=  null"
                                    :href="`/shop?filter_color_combo=${colorBubble.id}&from=mm`"
                                    
                                  >
                                    <img
                                      :src="
                                        `${imagePath}` + colorBubble.color_image
                                      "
                                    />
                                  </a>
                                  <a
                                    v-else-if="colorBubble.hex_code != '' && colorBubble.hex_code != null"
                                    :href="`/shop?filter_color_combo=${colorBubble.id}&from=mm`"
                                    
                                  >
                                    <span
                                      :class="[colorBubble.border]"
                                      :style="{
                                        height: '22px',
                                        width: '22px',
                                        display: 'block',
                                        'border-radius': '50%',
                                        'background-color':
                                          colorBubble.hex_code,
                                      }"
                                      >&nbsp;</span
                                    >
                                  </a>
                                </label>
                              </Popper>
                            </div>
                          </div>
                          <!-- End Filter Color Item -->
                        </div>
                      </div>
                    </div>
                    <div class="mb-md-4 col-md-6 col-lg shop-by-category" v-if="shopMenu.categoryTypes.length > 0">
                      <input class="invisible d-none" type="radio" id="shopByCat" value="shopByCat" v-model="selected" @click="uncheck('shopByCat')" name="shopByAccordion">
                      <h3 class="mega-menu-title">
                        <label class="d-flex flex-nowrap" for="shopByCat">
                          <template v-if="shopMenu.shoppingLists.length < 1">SHOP BY CATEGORY</template>
                          <template v-else>CATEGORIES</template>
                          <i class="fa-solid fa-plus"></i></label>
                      </h3>
                      <ul class="cat_container list-unstyled">
                        <li v-for="( categoryType, index ) in shopMenu.FirstRowCat" :key="index">
                          <a :href="`/${categoryType.seo_url}?from=mm`">{{categoryType.category_type}}</a>
                          <!-- <a :href="`/shop?filter_maincategory=${categoryType.id}&from=mm`">{{categoryType.category_type}}</a> -->
                        </li>
                      </ul>
                      <ul class="cat_container list-unstyled" v-if="shopMenu.SecondRowCat.length > 0">
                        <li v-for="( categoryType, index ) in shopMenu.SecondRowCat" :key="index">
                          <!-- <a :href="`/shop?filter_maincategory=${categoryType.id}&from=mm`">{{ categoryType.category_type }}</a> -->
                          <a :href="`/${categoryType.seo_url}?from=mm`">{{ categoryType.category_type }}</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="header-colors clearfix">
                    <p class="megamen_verbage no-letter-spacing">Learn more about our buying programs <a class="more-link" :href="`/buying-tools`">here</a>!</p>
                  </div>
                </div>
                <div class="right-menu" v-if="m.body">
                  <div class="images">
                    <div class="image_container">
                      <div v-html="m.body"></div>
                    </div>
                  </div>
                </div>
              </aside>
              <aside class="left-side"></aside>
            </div>
            <div v-else-if="m.is_mega_menu && m.url == 'locations'" class="mega-menu mega-menu-location">
              <div v-html="m.body"></div>
            </div>
            <div v-else-if="m.is_mega_menu" class="mega-menu">
              <div v-html="m.body"></div>
            </div>
          </template>
        </li>
      </ul>
    </nav>
      <div
        v-for="(section, index) in globalTopSection"
        class="mb-0 mt-0"
        :key="index"
        v-html="section.body"
      ></div>
  </div>
</template>


<script>
/* eslint-disable */
import { mapGetters } from "vuex";
import { mapMutations } from "vuex";
import { misc } from "@/mixins";
import axios from "axios";
import Popper from "vue3-popper";
export default {
  name: "Megamenu",
  components:{ Popper },
  data() {
    return {
      globalTopSection: [],
      menu: [],
      popularWidth: 'width-33',
      currentMenuIdx: 15,
      shopMenu: {
        programs: [],
        shoppingLists: [],
        categories: [],
        categoryTypes: [],
        FirstRowCat: [],
        SecondRowCat: [],
        colorBubbles: [],
        image: [],
      },
      selected: 0,
      previouslySelected: 0,
      megaMenuItemRef: [],
    };
  },
  mixins: [misc],
  computed: {
    ...mapGetters(["isLoggedIn", "canViewLoyalty"]),
  },
  created() {
    axios
      .get(process.env.VUE_APP_API_BASE + "auth/menus", {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((response) => {
        this.menu = response.data.menus;
        let css_class = 'width-33'
        this.menu.forEach(function (menuItem) {
          if (menuItem.is_mega_menu && menuItem.url === 'shop' && ( menuItem.body != null )) {
            css_class = 'width-25'
          }
        });
        this.popularWidth = css_class
      });

    localStorage.setItem("menu", JSON.stringify(this.menu));
  },

  mounted() {
    this.fetchTopGlobal();
    this.fetchMegaMenu();
    this.emitter.on("updateMegamenu", () => {
      this.fetchMegaMenu();
    });
  },

  methods: {
    ...mapMutations(["storeMegaMenu"]),
    showMegaMenu(idx) {
      if (this.currentMenuIdx == idx)
        this.currentMenuIdx = -1
      else
        this.currentMenuIdx = idx 
        
      this.previouslySelected = 0
      this.selected = 0
      let offsetPosition = 0;
      if (idx == 6) {
        offsetPosition = 299;
      } else {
        let refArr = this.megaMenuItemRef
        setTimeout(() => {
          const headerOffset = 199;
          const elementPosition = refArr[idx].getBoundingClientRect().top;
          offsetPosition = elementPosition + window.scrollY - headerOffset;
        }, 1000);
      } 
      
      window.scrollTo({
          top: offsetPosition,
          behavior: "smooth"
      });  
      
      if (this.currentMenuIdx == -1) {
        window.scrollTo({
          top: 10,
          behavior: "smooth"
      });
      }
    },
    setmegaMenuItemRef(el){
      if(el){
        this.megaMenuItemRef.push(el)
      }
    },
    uncheck: function(val) { 
      if (val === this.previouslySelected) {
        this.selected = false;
        this.previouslySelected = false
      } 
        this.previouslySelected = val;
      
    },
    fetchTopGlobal() {
      axios
        .post(`${process.env.VUE_APP_API_BASE}auth/getGlobalModules`, 
        {
          location: 'top',
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((response) => {
          this.globalTopSection = response.data.sections;
        });
    },
    fetchMegaMenu() {
      let megaMenuUrl =
        process.env.VUE_APP_API_BASE + "auth/getMegamenuNonUser";
      if (this.isLoggedIn)
        megaMenuUrl = process.env.VUE_APP_API_BASE + "auth/userMegamenu";

      axios
        .get(megaMenuUrl, {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((response) => {
          if (response.data.megamenu.programs != null && response.data.megamenu.programs.length) {
            this.shopMenu.programs = response.data.megamenu.programs;
          }
          if (response.data.megamenu.shoppingLists != null && response.data.megamenu.shoppingLists.length) {
            this.shopMenu.shoppingLists = response.data.megamenu.shoppingLists;
          }
          if (response.data.megamenu.categoryType != null && response.data.megamenu.categoryType.length) {
            this.shopMenu.categoryTypes = response.data.megamenu.categoryType;
          }
          if (this.shopMenu.categoryTypes != null && this.shopMenu.categoryTypes.length > 0) {
            this.shopMenu.FirstRowCat = this.shopMenu.categoryTypes.slice(0,8);
            this.shopMenu.SecondRowCat = this.shopMenu.categoryTypes.slice(8,15);
          }
          if (this.shopMenu.shoppingLists != null && this.shopMenu.shoppingLists.length > 0) {
            this.shopMenu.FirstRowCat = this.shopMenu.categoryTypes
            this.shopMenu.SecondRowCat = [];
          }
          this.shopMenu.colorBubbles = response.data.megamenu.colorBubbles;
        });
    },
    megaMenu(showOrHide, elementId, isMegaMenu) {
      if (isMegaMenu) {
        if (showOrHide) {
          document.getElementById(elementId).classList.add('display-mega-menu');
        } else {
          document.getElementById(elementId).classList.remove('display-mega-menu');
        }
      }
    },
  },
};
</script>
