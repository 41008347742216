<template>
  <section>
    <div>
      <div>
        <PageMetadata />
        <modal
          v-if="deleteRecipeModalShow"
          :closeFn="hideDeleteRecipeModal"
          closeBtn="cancel"
          :submitFn="removeRecipe"
          submitBtn="ok"
        >
          Are you sure, you want to delete recipe?
        </modal>

        <modal
          v-if="deleteProductModalShow"
          :closeFn="hideDeleteProductModal"
          closeBtn="cancel"
          :submitFn="removeRecipeProduct"
          submitBtn="ok"
        >
          Are you sure, you want to delete product?
        </modal>
        <loading-overlay
          :active="isLoading"
          is-full-page="true"
          loader="bars"
        />
        <flash-alert v-model:show="flashAlert.show" :level="flashAlert.level">
          <span v-html="flashAlert.msg"></span>
        </flash-alert>
        <h1 class="pb-0 mb-4 px-0">{{ getMetaDetails.page_h1 }}</h1>
        <p v-if="!isLoggedIn" class="font-weight-bold pb-3">
          NOTE: To expedite your quote, please <a href="/login">log in</a>.
        </p>
        <div
          class="content_below_heading"
          v-if="!!getMetaDetails.description"
          v-html="getMetaDetails.description"
        ></div>
      </div>
    </div>
    <section class="" id="eventplanner-page">
      <div
        class="table-responsive mb-4"
        v-if="isLoggedIn && savedPP.length > 0"
      >
        <h3 id="product-planner-tab" class="sub-header pb-0">
          Saved Product Planner
        </h3>
        <table class="table table-striped table-bordered text-center">
          <thead class="bg-grey">
            <tr>
              <th>Event Name</th>
              <th>Date</th>
              <th>Action</th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(ppl_quote, index) in savedPP" :key="index">
              <td>
                <a :href="`/edit-product-planner/${ppl_quote.id}`">{{
                  ppl_quote.event_name
                }}</a>
              </td>
              <td>{{ formatDateWithDashNew(ppl_quote.event_date) }}</td>
              <td>
                <a :href="`/edit-product-planner/${ppl_quote.id}`"
                  ><i class="fa-solid fa-pen-to-square"></i
                ></a>
                <delete-product-planner
                  :itemId="ppl_quote.id"
                ></delete-product-planner>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div
        class="row"
        v-if="
          programSettings.require_login == 0 ||
          (programSettings.require_login && isLoggedIn)
        "
      >
        <!--Grid column-->
        <div class="col-md-8 col-xl-12">
          <form
            id="contact-form"
            enctype="multipart/form-data"
            name="contact-form"
            method="POST"
            class="needs-validation"
            novalidate=""
          >
            <input
              type="hidden"
              name="eventplannersection"
              id="eventplannersection"
              value="1"
            />
            <!--Grid row-->
            <div class="row">
              <!--Grid column-->
              <div class="col-md-8">
                <div class="md-form form-group mb-3 has-error">
                  <label for="event_name" class=""
                    >Event Name <span class="red-asterick">*</span></label
                  >
                  <input
                    type="text"
                    id="event_name"
                    name="event_name"
                    v-model="plannerFields.event_name"
                    placeholder="Heather's wedding, John and Jenniffer's 50th anniversary, etc."
                    class="form-control"
                    required=""
                  />
                  <div
                    class="error-text"
                    v-if="plannerFields.errors.eventNameError"
                  >
                    Please enter event name.
                  </div>
                </div>
              </div>
              <!--Grid column-->
              <div class="col-md-4">
                <div class="md-form form-group mb-3">
                  <label for="event_date">
                    <div class="d-inline tool-tip">
                      <Popper
                        :class="d - inline"
                        :style="`display: inline !important;`"
                        :arrow="true"
                        :hover="true"
                        content="We recommend requesting a delivery date at least 2-3 days prior to the event date"
                        ><i class="fa-solid fa-circle-exclamation"></i>
                      </Popper>
                    </div>
                    Expected Delivery Date <span class="red-asterick">*</span>
                  </label>
                  <v-date-picker
                    v-model="plannerFields.event_date"
                    :first-day-of-week="1"
                    color="green"
                    range="false"
                    :masks="{ input: 'MM-DD-YYYY' }"
                    trim-weeks
                    :minDate="minDate" 
                    :maxDate="maxDate" 
                    :disabledDates="disabledDates"
                    @input="fetchProductsAvailability"
                  >
                    <template v-slot="{ inputValue, togglePopover }">
                      <div
                        @click="togglePopover()"
                        class="input-group flex-nowrap"
                      >
                        <input :value="inputValue" class="form-control" />
                        <span class="input-group-text" id="basic-addon2"
                          ><i class="fa-solid fa-calendar-days"></i
                        ></span>
                      </div>
                    </template>
                  </v-date-picker>
                  <div
                    class="error-text"
                    v-if="plannerFields.errors.eventDateError"
                  >
                    Please select event date.
                  </div>
                </div>
              </div>
            </div>
            <!--Grid row-->
            <!--Grid row-->
            <div class="row">
              <!--Grid column-->
              <div class="col-md-12">
                <div class="md-form form-group mb-3">
                  <label for="comment">Comments</label>
                  <textarea
                    type="text"
                    id="comment"
                    name="comment"
                    v-model="plannerFields.comments"
                    rows="2"
                    class="form-control md-textarea"
                  ></textarea>
                </div>
              </div>
            </div>
            <!--Grid row-->
            <!--new-section-->
            <div class="plannersections">
              <div
                v-for="(eventItem, eventItemIndex) in plannerFields.recipes"
                :key="eventItemIndex"
              >
                <div class="event-planner planner_1 mt-1">
                  <!--Grid row-->
                  <div class="row">
                    <!--Grid column-->
                    <div class="col-md-10">
                      <div class="md-form form-group mb-3">
                        <label for="name" class=""
                          >Item name <span class="red-asterick">*</span></label
                        >
                        <input
                          type="text"
                          v-model="eventItem.name"
                          placeholder="Bridal Bouquet, Centerpieces, etc."
                          id="item_name_1"
                          name="item_name_1"
                          class="form-control item_names"
                          required=""
                        />
                        <div
                          v-if="eventItem.errors.nameError"
                          class="error-text"
                        >
                          Please enter item name.
                        </div>
                      </div>
                    </div>
                    <!--Grid column-->
                    <div class="col-md-2">
                      <div class="md-form form-group mb-3">
                        <label for="email" class=""
                          >Quantities<span class="red-asterick">*</span></label
                        >
                        <input
                          type="number"
                          id="quantity_1"
                          v-model="eventItem.quantity"
                          @input="updateItemTotal(eventItemIndex)"
                          min="1"
                          name="quantity_1"
                          class="form-control quantities"
                          required=""
                        />
                        <div
                          v-if="eventItem.errors.quantityError"
                          class="error-text"
                        >
                          Please enter quantity.
                        </div>
                      </div>
                    </div>
                    <!--Grid column-->
                    <div class="col-md-12">
                      <div class="md-form form-group mb-3">
                        <label for="comment">Notes</label>
                        <textarea
                          type="text"
                          id="comment_1"
                          v-model="eventItem.comments"
                          name="comment_1"
                          rows="2"
                          class="form-control comments md-textarea"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div class="rw">
                    <div class="event_title col-md-12">Recipe</div>
                    <table
                      class="table table-borderless recipe-table border"
                      id="recipetable_1"
                    >
                      <thead>
                        <tr>
                          <th scope="col">Color</th>
                          <th scope="col">Flower</th>
                          <th scope="col" width="60px"></th>
                          <th scope="col" width="10%">Stems</th>
                          <th scope="col">Total</th>
                          <th scope="col" width="58px"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          :id="`tr_1_${recipeIndex}`"
                          class="tr_1"
                          v-for="(item, recipeIndex) in eventItem.products"
                          :key="recipeIndex"
                        >
                          <td class="width-300">
                            <div
                              class="md-form form-group"
                              data-select2-id="4"
                              @change="
                                changeFlowerList(
                                  $event,
                                  recipeIndex,
                                  eventItemIndex
                                )
                              "
                            >
                              <label>Color</label>
                              <div class="dropdown color-bubble-box">
                                <button
                                  :ref="initColorDd"
                                  :data-id-index="`${eventItemIndex}_${recipeIndex}`"
                                  role="button"
                                  class="dropdown-toggle form-control"
                                  :id="`miniCartLinkk_${eventItemIndex}_${recipeIndex}`"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                  type="button"
                                  @click="
                                    showColorDd(
                                      `${eventItemIndex}_${recipeIndex}`
                                    )
                                  "
                                >
                                  <span
                                    v-if="
                                      'color_image' in item && item.color_image
                                    "
                                    class="color-bubble"
                                    ><img
                                      class="color_bubble_img"
                                      :src="item.color_image"
                                    />
                                  </span>
                                  <span
                                    v-else-if="
                                      'hex_code' in item && item.hex_code
                                    "
                                    class="color-bubble"
                                    :class="item.border"
                                    :style="{ '--bg': item.hex_code }"
                                  ></span>
                                  {{ item.color_id || "Select" }}
                                </button>
                                <ul
                                  class="dropdown-menu"
                                  style="margin: 0"
                                  :aria-labelledby="`miniCartLinkk_${eventItemIndex}_${recipeIndex}`"
                                >
                                  <li
                                    v-for="(
                                      color, colorIndex
                                    ) in colorBubbleList"
                                    :key="colorIndex"
                                  >
                                    <a
                                      :value="color.color"
                                      class="dropdown-item"
                                      href="#"
                                      @click.prevent="
                                        setColorDdVal(item, color)
                                      "
                                    >
                                      <span
                                        v-if="
                                          'color_image' in color &&
                                          color.color_image
                                        "
                                        class="color-bubble align-middle"
                                        ><img
                                          class="color_bubble_img"
                                          :src="color.color_image"
                                        />
                                      </span>
                                      <span
                                        v-else-if="
                                          'hex_code' in color && color.hex_code
                                        "
                                        class="color-bubble align-middle"
                                        :class="color.border"
                                        :style="{ '--bg': color.hex_code }"
                                      ></span>
                                      <span class="align-middle">
                                        {{ color.color }}</span
                                      >
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="md-form form-group flowersdiv">
                              <label>Flower</label>
                              <auto-suggestions
                                :ref="`flower_autocomplate_${recipeIndex}_${eventItemIndex}`"
                                @search="flowerUpdated"
                                @input="flowerChange"
                                :recipeIndex="recipeIndex"
                                :eventItemIndex="eventItemIndex"
                                :items="newFlowerList"
                                :color="item.color_id"
                                :style="flowerStyling"
                                :key="global"
                                :selected="item.flowerDetails"
                              />
                              <div
                                v-if="item.errors.flowerNameError"
                                class="error-text"
                              >
                                Please select flower.
                              </div>
                              <span
                                class="warn warn_1"
                                v-if="
                                  Object.keys(item.flowerDetails).length > 0 &&
                                  item.flowerDetails.status == 0 &&
                                  item.flowerDetails.name != ''
                                "
                              >
                                <Popper
                                  :arrow="true"
                                  :hover="true"
                                  content="Might not be available for this date"
                                  ><i
                                    class="fa-solid fa-circle-exclamation text-danger"
                                  ></i>
                                </Popper>
                              </span>
                              <span
                                class="warn warn_1"
                                v-if="
                                  Object.keys(item.flowerDetails).length > 0 &&
                                  item.flowerDetails.status == 2 &&
                                  item.flowerDetails.name != ''
                                "
                              >
                                <Popper
                                  :arrow="true"
                                  :hover="true"
                                  content="Might not be available for this date"
                                  ><i class="fa-solid fa-triangle-exclamation text-warning"></i>
                                </Popper>
                              </span>
                            </div>
                          </td>
                          <td>
                            <div
                              class="md-form form-group"
                              v-if="Object.keys(item.flowerDetails).length > 0"
                            >
                              <label>&nbsp;</label>
                              <img
                                v-if="item.flowerDetails.product_image"
                                :src="
                                  `${imagePath}` +
                                  item.flowerDetails.product_image
                                "
                                class="m-0"
                              />
                              <img
                                v-else
                                :src="`${imagePath}` + getDefaultImage(1)"
                                class="m-0"
                              />
                            </div>
                          </td>
                          <td>
                            <div class="md-form form-group">
                              <label>Stems</label>
                              <input
                                type="number"
                                min="1"
                                @input="updateItemTotal(eventItemIndex)"
                                v-model="item.quantity"
                                class="form-control stemscount_1 stems"
                                required=""
                              />
                              <div
                                v-if="item.errors.stemQuantityError"
                                class="error-text"
                              >
                                Please enter valid stem quantity.
                              </div>
                            </div>
                          </td>
                          <td>
                            <label>Total</label>
                            <strong class="stemsstr_1 mt-0" id="stemsstr_1_1">
                              <template v-if="item.total > 0">
                                {{ item.total }}
                              </template>
                              <template v-else> 0 </template>
                            </strong>
                          </td>
                          <td>
                            <label>&nbsp;</label>
                            <button
                              @click="
                                showDeleteProductModal(
                                  eventItemIndex,
                                  recipeIndex
                                )
                              "
                              type="button"
                              id="remove_1_1"
                              class="btn btn-danger removerecipe remove_1"
                            >
                              <i class="fa-solid fa-circle-xmark"></i>
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <button
                      @click="addRecipe(eventItemIndex)"
                      type="button"
                      id="add_1"
                      class="btn btn-success plus addrecipe"
                    >
                      <i class="fa-solid fa-plus-circle"></i>
                    </button>
                    &nbsp;&nbsp;
                    <span>Add to your recipe</span>
                  </div>
                </div>

                <button
                  @click="showDeleteRecipeModal(eventItemIndex)"
                  type="button"
                  id="fullSectionMinus"
                  class="btn btn-danger minus my-3"
                >
                  <i class="fa-solid fa-circle-minus"></i>
                </button>
                Delete <span> {{ eventItem.name }} Item</span>
              </div>
            </div>
            <div>
              <button
                @click="addEventItem"
                type="button"
                id="fullSectionPlus"
                class="btn btn-success plus"
              >
                <i class="fa-solid fa-plus-circle"></i>
              </button>

              <span> Add item</span>
            </div>
            <div class="mt_top">
              <h2>Total</h2>
            </div>
            <table class="table table-striped" id="eventdatatable">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Color</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="eventdata"
                  v-for="(
                    flowerItem, flowerIndex
                  ) in plannerFields.selectedFlowerRecipes"
                  :key="flowerIndex"
                >
                  <td>{{ flowerItem.value }}</td>
                  <td>{{ flowerItem.color }}</td>
                  <td>{{ flowerItem.totalQuantity }}</td>
                </tr>
                <tr class="white_bg">
                  <td></td>
                  <td style="text-align: right"><b>Total Stems</b></td>
                  <td>
                    <b>{{ plannerFields.totalStems }}</b>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="info-wrapper">
              <h2>Your Information:</h2>
              <div class="row mb-3 mb-lg-4">
                <div class="col-md-4">
                  <p class="mb-0">Name:</p>
                </div>
                <div class="col-md-4">
                  <input
                    type="text"
                    v-model="plannerFields.name"
                    class="form-control"
                    :disabled="isLoggedIn && plannerFields.name != null"
                    id="cust_name"
                    required=""
                  />
                  <span class="text-danger" v-if="plannerFields.errors.name"
                    >Please enter valid name.</span
                  >
                </div>
              </div>
              <div class="row mb-3 mb-lg-4">
                <div class="col-md-4">
                  <p class="mb-0">Company:</p>
                </div>
                <div class="col-md-4">
                  <input
                    type="text"
                    v-model="plannerFields.company_name"
                    class="form-control"
                    name="comp_name"
                    :disabled="isLoggedIn && plannerFields.company_name != null"
                    id="comp_name"
                    required=""
                  />
                  <span
                    class="text-danger"
                    v-if="plannerFields.errors.company_name"
                    >Please enter valid company name.</span
                  >
                </div>
              </div>
              <div class="row mb-3 mb-lg-4">
                <div class="col-md-4">
                  <p class="mb-0">Phone:</p>
                </div>
                <div class="col-md-4">
                  <input
                    type="text"
                    v-model="plannerFields.phone"
                    class="form-control"
                    :disabled="isLoggedIn"
                    name="cust_phone"
                    id="cust_phone"
                  />
                </div>
              </div>
              <div class="row mb-3 mb-lg-4">
                <div class="col-md-4">
                  <p class="mb-0">Address:</p>
                </div>
                <div class="col-md-4">
                  <!---->
                  <input
                    type="text"
                    v-model="plannerFields.address"
                    class="form-control"
                    :disabled="isLoggedIn && plannerFields.address != null"
                    name="cust_address"
                    id="cust_address"
                  />
                  <span
                    class="text-danger"
                    v-if="plannerFields.errors && plannerFields.errors.address"
                    >Please enter address</span
                  >
                </div>
              </div>
              <div class="row mb-3 mb-lg-4">
                <div class="col-md-4">
                  <p class="mb-0">City:</p>
                </div>
                <div class="col-md-4">
                  <input
                    type="text"
                    :disabled="isLoggedIn && plannerFields.city != null"
                    v-model="plannerFields.city"
                    class="form-control"
                    name="cust_city"
                    id="cust_city"
                  />
                  <span
                    class="text-danger"
                    v-if="plannerFields.errors && plannerFields.errors.city"
                    >Please enter valid city.</span
                  >
                </div>
              </div>
              <div class="row mb-3 mb-lg-4">
                <div class="col-md-4">
                  <p class="mb-0">State:</p>
                </div>
                <div class="col-md-4">
                  <!--<input type="text" class="form-control" name="cust_state" id="cust_state" value=""  />-->
                  <select
                    class="form-select"
                    v-model="plannerFields.state"
                    :disabled="isLoggedIn && plannerFields.state != null"
                    name="cust_state"
                    id="cust_state"
                    required=""
                  >
                    <option value="">Select a State</option>
                    <option value="AL">Alabama</option>
                    <option value="AK">Alaska</option>
                    <option value="AZ">Arizona</option>
                    <option value="AR">Arkansas</option>
                    <option value="CA">California</option>
                    <option value="CO">Colorado</option>
                    <option value="CT">Connecticut</option>
                    <option value="DE">Delaware</option>
                    <option value="DC">District Of Columbia</option>
                    <option value="FL">Florida</option>
                    <option value="GA">Georgia</option>
                    <option value="HI">Hawaii</option>
                    <option value="ID">Idaho</option>
                    <option value="IL">Illinois</option>
                    <option value="IN">Indiana</option>
                    <option value="IA">Iowa</option>
                    <option value="KS">Kansas</option>
                    <option value="KY">Kentucky</option>
                    <option value="LA">Louisiana</option>
                    <option value="ME">Maine</option>
                    <option value="MD">Maryland</option>
                    <option value="MA">Massachusetts</option>
                    <option value="MI">Michigan</option>
                    <option value="MN">Minnesota</option>
                    <option value="MS">Mississippi</option>
                    <option value="MO">Missouri</option>
                    <option value="MT">Montana</option>
                    <option value="NE">Nebraska</option>
                    <option value="NV">Nevada</option>
                    <option value="NH">New Hampshire</option>
                    <option value="NJ">New Jersey</option>
                    <option value="NM">New Mexico</option>
                    <option value="NY">New York</option>
                    <option value="NC">North Carolina</option>
                    <option value="ND">North Dakota</option>
                    <option value="OH">Ohio</option>
                    <option value="OK">Oklahoma</option>
                    <option value="OR">Oregon</option>
                    <option value="PA">Pennsylvania</option>
                    <option value="RI">Rhode Island</option>
                    <option value="SC">South Carolina</option>
                    <option value="SD">South Dakota</option>
                    <option value="TN">Tennessee</option>
                    <option value="TX">Texas</option>
                    <option value="UT">Utah</option>
                    <option value="VT">Vermont</option>
                    <option value="VA">Virginia</option>
                    <option value="WA">Washington</option>
                    <option value="WV">West Virginia</option>
                    <option value="WI">Wisconsin</option>
                    <option value="WY">Wyoming</option>
                  </select>

                  <span class="text-danger" v-if="plannerFields.errors.state"
                    >Please select state.</span
                  >
                </div>
              </div>
              <div class="row mb-3 mb-lg-4">
                <div class="col-md-4">
                  <p class="mb-0">Zip Code:</p>
                </div>
                <div class="col-md-4">
                  <input
                    type="text"
                    v-model="plannerFields.zip_code"
                    class="form-control"
                    :disabled="isLoggedIn && plannerFields.zip_code != null"
                    name="cust_zip_code"
                    id="cust_zip_code"
                    required=""
                  />
                  <span class="text-danger" v-if="plannerFields.errors.zip_code"
                    >Please enter valid zipcode.</span
                  >
                </div>
              </div>
              <div class="row mb-3 mb-lg-4">
                <div class="col-md-4">
                  <p class="mb-0">Email:</p>
                </div>
                <div class="col-md-4">
                  <input
                    type="email"
                    v-model="plannerFields.email"
                    class="form-control"
                    name="cust_email"
                    :disabled="isLoggedIn && plannerFields.email != null"
                    id="cust_email"
                    required=""
                  />
                  <span class="text-danger" v-if="plannerFields.errors.email"
                    >Please enter valid email.</span
                  >
                </div>
              </div>
              <div class="row mb-3 mb-lg-4">
                <div class="col-md-4">
                  <p class="mb-0">Picture Upload:</p>
                </div>
                <div class="col-md-4">
                  <input
                    type="file"
                    ref="file"
                    class="form-control"
                    name="pictures[]"
                    multiple="true"
                    id="pictures"
                    @change="onFileChange"
                    accept="image/*"
                  />
                </div>
                <span id="error_msg" style="color: #ff0000"></span>
              </div>
              <div class="row mb-3 mb-lg-4">
                <div class="col-md-4">
                  <p class="mb-0">Captcha:</p>
                </div>
                <div class="col-md-4 captcha_wrapper">
                  <vue-recaptcha
                    v-if="showRecaptcha"
                    sitekey="6Le4FqEcAAAAANEKe-3kFDveux0h6aC3gOjBnLzJ"
                    size="normal"
                    theme="light"
                    :tabindex="0"
                    @verify="recaptchaVerified"
                    @expire="recaptchaExpired"
                    @fail="recaptchaFailed"
                    ref="vueRecaptcha"
                  >
                  </vue-recaptcha>
                  <span
                    id="error_msg_2"
                    v-if="!isPageLoad && !isCaptchaVerified"
                    style="color: #ff0000"
                  >
                    Captcha is required.</span
                  >
                </div>
              </div>
              <div class="btn-wrap">
                <input
                  type="button"
                  value="SUBMIT"
                  id="btfrom"
                  @click="submitPlanner"
                />
                <input
                  type="button"
                  v-if="isLoggedIn"
                  value="SAVE & CONTINUE"
                  id="btfrom"
                  @click="savePlanner"
                />
                <input
                  type="button"
                  v-if="isLoggedIn"
                  value="SAVE & NEW"
                  id="btfrom"
                  @click="saveClearPlanner"
                />
              </div>
            </div>
          </form>
          <p class="pt-4">
            Please note that due to varying stem counts per bunch among the vast
            array of flowers we carry, your Mayesh quote will most likely be
            slightly different than the total from your product planner
            worksheet you provided. Your sales representative will review the
            details with you before proceeding with the final order. Click here
            to get our free
            <a href="https://info.mayesh.com/flower-guide-offer" target="_blank"
              >Ultimate Flower Guide</a
            >
            used for planning product palettes for every day designs.
          </p>
        </div>
        <!--Grid column-->
      </div>
      <div v-else>
        To expedite your quote, please
        <a class="display-contents" :href="'/login'"> Login </a>.
      </div>
    </section>
  </section>
</template>
<script>
import {
  Autocomplete,
  FlashAlert,
  DeleteProductPlanner,
  Modal,
  PageMetadata,
} from "@/components";
import bootstrap from "bootstrap/dist/js/bootstrap.min.js";
import { misc } from "@/mixins";
import Popper from "vue3-popper";
import axios from "axios";
import vueRecaptcha from "vue3-recaptcha2";

export default {
  name: "ProductPlanner",
  mixins: [misc],
  components: {
    "auto-suggestions": Autocomplete,
    FlashAlert,
    Popper,
    vueRecaptcha,
    Modal,
    DeleteProductPlanner,
    PageMetadata,
  },
  data() {
    return {
      global: 0,
      colorDdInst: {},
      newFlowerList: [],
      showRecaptcha: true,
      files: [],
      deleteRecipeModalShow: false,
      deleteProductModalShow: false,
      isLoading: false,
      availableDates: {},
      disabledDates: [],
      mixDate: '',
      maxDate: '',
      isPageLoad: true,
      isCaptchaVerified: false,
      flowerStyling: {
        position: "absolute",
        width: "100%",
      },
      isSubmitEnable: false,
      colorBubble: {},
      colorBubbleList: {},
      ppl_autosave_time: 0,
      plannerFields: {
        product_planner_id: "",
        event_name: "",
        event_date: new Date(),
        comments: "",
        totalStems: 0,
        customer_id: 1,
        company_name: "",
        name: "",
        last_name: "",
        email: "",
        phone: "",
        address: "",
        city: "",
        state: "",
        zip_code: "",
        selectedFlowerRecipes: [],
        itemName: "",
        quantity: 1,
        notes: "",
        errors: {
          eventNameError: false,
          eventDateError: false,
        },
        recipes: [
          {
            name: "",
            quantity: 1,
            comments: "",
            errors: {
              quantityError: false,
              nameError: false,
            },
            products: [
              {
                color_id: "",
                color_name: "",
                product_id: "",
                product_name: "",
                unit: "",
                quantity: 1,
                flower: {},
                errors: {
                  colorNameError: false,
                  flowerNameError: false,
                  stemQuantityError: false,
                },
                flowerDetails: {},
              },
            ],
          },
        ],
      },
      clonePlannerFields: {
        product_planner_id: "",
        event_name: "",
        event_date: new Date(),
        comments: "",
        totalStems: 0,
        selectedFlowerRecipes: [],
        itemName: "",
        quantity: 1,
        notes: "",
        errors: {
          eventNameError: false,
          eventDateError: false,
        },
        recipes: [
          {
            name: "",
            quantity: 1,
            comments: "",
            errors: {
              quantityError: false,
              nameError: false,
            },
            products: [
              {
                color_id: "",
                color_name: "",
                product_id: "",
                product_name: "",
                unit: "",
                quantity: 1,
                flower: {},
                errors: {
                  colorNameError: false,
                  flowerNameError: false,
                  stemQuantityError: false,
                },
                flowerDetails: {},
              },
            ],
          },
        ],
      },
      recipeToDelete: "",
      recipeProduct: {
        eventItemIndex: "",
        receipeIndex: "",
      },
      savedPP: [],
      programSettings: {},
      lastCounter: 1,
    };
  },
  mounted() {
    this.fetchColorBubbles();
    localStorage.setItem("currentPage", "product-planner");
    let productTypes = localStorage.getItem("productTypes");
    let site_settings = JSON.parse(localStorage.getItem("site_settings"));
    if (site_settings.ppl_autosave) {
      this.ppl_autosave_time = site_settings.ppl_autosave * 1000;
    } else {
      this.ppl_autosave_time = 60000;
    }

    if (productTypes == null) {
      this.fetchProductsTypes();
    }

    this.getAllProducts();
    this.getProgramSettings();
    this.fetchMetaDetails();
    this.fetchSavedPP();
    this.getUserDetails();
    this.emitter.on("deletedPP", () => {
      this.PPDeleted();
    });

    this.emitter.on("deleted_pp_details", (payload) => {
      this.delete_product_planner_GA(payload);
    });

    this.plannerFields.recipes[0].products[0].flowerList = this.newFlowerList;
    setTimeout(() => {
      this.colorBubble = JSON.parse(localStorage.getItem("colorBubbles"));
    }, 500);
  },
  methods: {
    initiate_ppl_autosave() {
      setInterval(() => {
        this.savePlannerPeriodically();
      }, this.ppl_autosave_time);
    },
    setColorDdVal(item, color) {
      item.color_id = color.color;

      if ("color_image" in color && color.color_image) {
        item.color_image = color.color_image;
        item.hex_code = "";
      } else if ("hex_code" in color && color.hex_code) {
        item.hex_code = color.hex_code;
        item.color_image = "";
      }
      item.border = color.border;
    },
    initColorDd(el) {
      if (el) {
        const idx = el.dataset.idIndex;
        this.colorDdInst[idx] = new bootstrap.Dropdown(el);
      }
    },
    showColorDd(idIndex) {
      if (idIndex in this.colorDdInst) {
        this.colorDdInst[idIndex].toggle();
      }
    },
    fetchProductsTypes() {
      axios
        .get(
          process.env.VUE_APP_API_BASE + "auth/getProductTypes",
          {}, // the data to post
          {
            headers: {
              "Content-type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.productTypes = response.data.types;
          localStorage.setItem(
            "productTypes",
            JSON.stringify(response.data.types)
          );
        });
    },
    hideDeleteRecipeModal() {
      this.recipeToDelete = "";
      this.deleteRecipeModalShow = false;
    },
    hideDeleteProductModal() {
      this.recipeProduct.eventItemIndex = "";
      this.recipeProduct.receipeIndex = "";
      this.deleteProductModalShow = false;
    },

    showDeleteRecipeModal(eventItemIndex) {
      this.recipeToDelete = eventItemIndex;
      this.deleteRecipeModalShow = true;
    },
    showDeleteProductModal(eventItemIndex, recipeIndex) {
      this.recipeProduct.eventItemIndex = eventItemIndex;
      this.recipeProduct.receipeIndex = recipeIndex;
      this.deleteProductModalShow = true;
    },

    PPDeleted() {
      this.fetchSavedPP();
    },

    delete_product_planner_GA(payload) {
      this.delete_planner_GA(payload)
    },

    getProgramSettings() {
      axios
        .get(process.env.VUE_APP_API_BASE + "auth/getProgramSettings", {
          headers: {
            "Content-type": "application/json;multipart/form-data",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((response) => {
          if (response.data.error === false) {
            response.data.disabled_dates.forEach((disabledDate) => {
              const formatted = this.appendTimeForBrowser(disabledDate);
              this.disabledDates.push({
                start: formatted,
                end: formatted,
                date: disabledDate,
              });
            });
            this.minDate = this.appendTimeForBrowser(response.data.min_date);
            this.maxDate = this.appendTimeForBrowser(response.data.max_date);

            this.programSettings = response.data.settings;
            this.availableDates = response.data.available_dates;

            if (this.availableDates.length > 0) {
              this.plannerFields.event_date = this.appendTimeForBrowser(
                this.availableDates[0]
              );
            }
          }
        });
    },
    recaptchaVerified() {
      this.isSubmitEnable = true;
      this.isCaptchaVerified = true;
    },
    recaptchaExpired() {
      this.$refs.vueRecaptcha.reset();
      this.isSubmitEnable = false;
      this.isCaptchaVerified = false;
    },
    recaptchaFailed() {
      this.isSubmitEnable = false;
      this.isCaptchaVerified = false;
    },
    validateForm() {
      this.plannerFields.errors.eventNameError = false;
      this.plannerFields.errors.eventDateError = false;
      let errorFlag = false;
      for (let plannerKey in this.plannerFields) {
        if (plannerKey == "event_name") {
          if (!this.plannerFields.event_name.trim()) {
            errorFlag = true;
            this.plannerFields.errors.eventNameError = true;
          }
        }
        if (plannerKey == "event_date") {
          if (!this.plannerFields.event_date) {
            errorFlag = true;
            this.plannerFields.errors.eventDateError = true;
          }
        }

        if (plannerKey == "event_date") {
          if (!this.plannerFields.event_date) {
            errorFlag = true;
            this.plannerFields.errors.eventDateError = true;
          }
        }

        if (plannerKey == "name") {
          if (
            !this.plannerFields.name.trim() ||
            this.plannerFields.name.trim() == null
          ) {
            errorFlag = true;
            this.plannerFields.errors.name = true;
          }
        }

        if (plannerKey == "company_name") {
          if (
            !this.plannerFields.company_name.trim() ||
            this.plannerFields.company_name.trim() == null
          ) {
            errorFlag = true;
            this.plannerFields.errors.company_name = true;
          }
        }

        if (plannerKey == "address") {
          if (
            !this.plannerFields.address.trim() ||
            this.plannerFields.address.trim() == null
          ) {
            errorFlag = true;
            this.plannerFields.errors.address = true;
          }
        }

        if (plannerKey == "city") {
          if (
            !this.plannerFields.city.trim() ||
            this.plannerFields.city.trim() == null
          ) {
            errorFlag = true;
            this.plannerFields.errors.city = true;
          }
        }

        if (plannerKey == "state") {
          if (
            !this.plannerFields.state.trim() ||
            this.plannerFields.state.trim() == null
          ) {
            errorFlag = true;
            this.plannerFields.errors.state = true;
          }
        }

        if (plannerKey == "zip_code") {
          if (
            !this.plannerFields.zip_code.trim() ||
            this.plannerFields.zip_code.trim() == null
          ) {
            errorFlag = true;
            this.plannerFields.errors.zip_code = true;
          }
        }

        if (plannerKey == "email") {
          const emailRegex =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if (
            !emailRegex.test(
              String(this.plannerFields.email).toLowerCase() ||
                this.plannerFields.email.trim() == null
            )
          ) {
            errorFlag = true;
            this.plannerFields.errors.email = true;
          }
        }

        if (plannerKey == "recipes") {
          for (let recipe of this.plannerFields["recipes"]) {
            recipe.errors.nameError = false;
            recipe.errors.quantityError = false;
            if (!recipe.name.trim()) {
              errorFlag = true;
              recipe.errors.nameError = true;
            }
            if (!recipe.quantity) {
              errorFlag = true;
              recipe.errors.quantityError = true;
            }
            if (recipe.products != undefined) {
              for (let product of recipe.products) {
                product.errors.stemQuantityError = false;
                product.errors.flowerNameError = false;
                if (product.quantity < 1) {
                  errorFlag = true;
                  product.errors.stemQuantityError = true;
                }
                if (!product.product_name.trim()) {
                  errorFlag = true;
                  product.errors.flowerNameError = true;
                }
              }
            }
          }
        }
      }
      return errorFlag;
    },

    validatePlannerOnly() {
      this.plannerFields.errors.eventNameError = false;
      this.plannerFields.errors.eventDateError = false;
      let errorFlag = false;
      for (let plannerKey in this.plannerFields) {
        if (plannerKey == "event_name") {
          if (!this.plannerFields.event_name.trim()) {
            errorFlag = true;
            this.plannerFields.errors.eventNameError = true;
          }
        }
        if (plannerKey == "event_date") {
          if (!this.plannerFields.event_date) {
            errorFlag = true;
            this.plannerFields.errors.eventDateError = true;
          }
        }

        if (plannerKey == "event_date") {
          if (!this.plannerFields.event_date) {
            errorFlag = true;
            this.plannerFields.errors.eventDateError = true;
          }
        }

        if (plannerKey == "recipes") {
          for (let recipe of this.plannerFields["recipes"]) {
            recipe.errors.nameError = false;
            recipe.errors.quantityError = false;

            if (!recipe.name.trim()) {
              errorFlag = true;
              recipe.errors.nameError = true;
            }
            if (!recipe.quantity) {
              errorFlag = true;
              recipe.errors.quantityError = true;
            }
            if (recipe.products != undefined) {
              for (let product of recipe.products) {
                product.errors.stemQuantityError = false;
                product.errors.flowerNameError = false;
                if (product.quantity < 1) {
                  errorFlag = true;
                  product.errors.stemQuantityError = true;
                }
                if (!product.product_name) {
                  errorFlag = true;
                  product.errors.flowerNameError = true;
                }
              }
            }
          }
        }
      }
      return errorFlag;
    },

    getUserDetails() {
      if (this.isLoggedIn) {
        this.isLoading = true;
        try {
          axios
            .get(process.env.VUE_APP_API_BASE + "auth/miniprofile", {
              headers: {
                "Content-type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            })
            .then((response) => {
              let customerInfo = response.data.customer;
              this.plannerFields.name = customerInfo.first_name + " " + customerInfo.last_name;
              this.plannerFields.company_name = customerInfo.company_name;
              this.plannerFields.phone = customerInfo.phone;
              this.plannerFields.address = customerInfo.shipping_address;
              this.plannerFields.state = customerInfo.shipping_state;
              this.plannerFields.city = customerInfo.shipping_city;
              this.plannerFields.zip_code = customerInfo.shipping_zip_code;
              this.plannerFields.email = customerInfo.email;
              this.plannerFields.customer_id = customerInfo.id;
              this.isLoading = false;
            });
        } catch {
          this.isLoading = false;
        }
      }
    },
    validateAvailability(flowerObject) {
      if (flowerObject.product_id == "" || flowerObject.product_id == undefined)
        return 0;

      let allFlowers = this.newFlowerList;
      let obj = allFlowers.find((o) => {
        if (o.product_id == flowerObject.product_id) {
          if (o.status == 0) return "0";
          return o.status; // stop searching
        }
      });

      return obj.status;
    },
    fetchProductsAvailability() {
      let dat = this.plannerFields.event_date;
      try {
        axios
          .post(
            process.env.VUE_APP_API_BASE + "auth/getPPProducts",
            { date: dat },
            {
              headers: {
                "Content-type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            this.newFlowerList = response.data.products;
            let receipes = this.plannerFields.recipes;
            receipes.forEach((receipe) => {
              if (receipe.products != undefined) {
                receipe.products.forEach((product) => {
                  if (Object.keys(product.flowerDetails).length > 0) {
                    let status = this.validateAvailability(product.flowerDetails);
                    if (status == false) status = 0;
                    product.flowerDetails.status = status;
                  }
                });
              }
            });
          });
      } catch {
        console.log("Something went wrong");
      }
    },
    getAllProducts() {
      try {
        axios
          .post(process.env.VUE_APP_API_BASE + "auth/getPPProducts", {
            headers: {
              "Content-type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            this.newFlowerList = response.data.products;
          });
      } catch {
        console.log("Something went wrong");
      }
    },

    fetchSavedPP() {
      try {
        if (this.isLoggedIn) {
          axios
            .get(
              process.env.VUE_APP_API_BASE + "auth/getSavedProductPlanners",
              {
                headers: {
                  "Content-type": "application/json",
                  Authorization: "Bearer " + localStorage.getItem("token"),
                },
              }
            )
            .then((response) => {
              this.savedPP = response.data.product_planners;
            });
        }
      } catch {
        console.log("Something went wrong");
      }
    },

    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      Array.from(files).forEach((file) => {
        this.createImage(file);
      });
    },
    createImage(file) {
      var reader = new FileReader();
      let fileArr = {};
      var vm = this;
      reader.onload = (e) => {
        fileArr = { filename: file.name, file_content: e.target.result };
        vm.files.push(fileArr);
      };
      reader.readAsDataURL(file);
    },
    addRecipe(eventItemIndex) {
      this.isLoading = true;
      if (this.plannerFields.recipes[eventItemIndex].products == undefined)
        this.plannerFields.recipes[eventItemIndex].products = []
      
      this.plannerFields.recipes[eventItemIndex].products.push({
        color_id: "",
        color_name: "",
        product_id: "",
        product_name: "",
        unit: "stem",
        quantity: 1,
        flower: {},
        errors: {
          colorNameError: false,
          flowerNameError: false,
          stemQuantityError: false,
        },
        flowerDetails: {},
      });
      this.add_product_product_planner_GA()
      this.isLoading = false;
    },
    removeRecipeProduct() {
      let recipeProductIndex = 0;
      this.delete_product_product_planner_GA( this.plannerFields.recipes[
        this.recipeProduct.eventItemIndex
      ].products[this.recipeProduct.receipeIndex]);

      this.plannerFields.recipes[
        this.recipeProduct.eventItemIndex
      ].products.splice(this.recipeProduct.receipeIndex, 1);
      setTimeout(() => {
        this.plannerFields.recipes[
          this.recipeProduct.eventItemIndex
        ].products.map((product) => {
          this.$refs[
            "flower_autocomplate" +
              "_" +
              recipeProductIndex +
              "_" +
              this.recipeProduct.eventItemIndex
          ][0].setResult(product.flowerDetails);
          recipeProductIndex++;
        });
        this.hideDeleteProductModal();
      }, 50);
    },
    addEventItem() {
      this.isLoading = true;
      this.plannerFields.recipes.push({
        name: "",
        quantity: 1,
        comments: "",
        errors: {
          nameError: false,
          quantityError: false,
        },
        products: [
          {
            color_id: "",
            color_name: "",
            product_id: "",
            product_name: "",
            unit: "",
            quantity: 1,
            errors: {
              colorNameError: false,
              flowerNameError: false,
              stemQuantityError: false,
            },
            flower: {},
            flowerDetails: {},
          },
        ],
      });
      this.add_item_ppl_GA()
      this.isLoading = false;
    },

    removeRecipe() {
      this.delete_item_product_planner_GA()
      this.plannerFields.recipes.splice(this.recipeToDelete, 1);
      this.hideDeleteRecipeModal();
      ++this.global;

      // update total
      this.plannerFields.totalStems = 0;
      this.plannerFields.selectedFlowerRecipes = [];
      let totalQuantities = [];
      this.plannerFields.recipes.forEach((item) => {
        item.products.forEach((recipe) => {
          this.plannerFields.totalStems += recipe.total;
          if (recipe.flowerDetails && recipe.flowerDetails.name) {
            let slug = recipe.flowerDetails.name.split(" ");
            slug = recipe.color
              ? slug.join("_") + "_" + recipe.color
              : slug.join("_");
            slug = slug.toLowerCase();
            if (totalQuantities[slug]) {
              totalQuantities[slug].totalQuantity += recipe.total;
            } else {
              recipe.flower.totalQuantity = recipe.total;
              recipe.flower.value = recipe.flowerDetails
                ? recipe.flowerDetails.name
                : "";
              totalQuantities[slug] = recipe.flower;
            }
          }
        });
      });

      Object.keys(totalQuantities).forEach((k) => {
        this.plannerFields.selectedFlowerRecipes.push(totalQuantities[k]);
      });
    },

    updateItemTotal(eventItemIndex) {

      let quantity = 0;
      if (this.plannerFields.recipes[eventItemIndex] != undefined) {
        quantity = this.plannerFields.recipes[eventItemIndex].quantity;
      }
      
      this.plannerFields.totalStems = 0;
      this.plannerFields.selectedFlowerRecipes = [];
      if (this.plannerFields.recipes[eventItemIndex] != undefined) {
        this.plannerFields.recipes[eventItemIndex].products =
          this.plannerFields.recipes[eventItemIndex].products.map((recipe) => {
          recipe.total = recipe.quantity * quantity;
          recipe.flower.totalQuantity = recipe.total;
          recipe.flower.color = recipe.color_id;
          return recipe;
        });
      }
      let totalQuantities = [];
      this.plannerFields.recipes.forEach((item) => {
        item.products.forEach((recipe) => {
          this.plannerFields.totalStems += recipe.total;
          if (recipe.flowerDetails && recipe.flowerDetails.name) {
            let slug = recipe.flowerDetails.name.split(" ");
            slug = recipe.color
              ? slug.join("_") + "_" + recipe.color
              : slug.join("_");
            slug = slug.toLowerCase();
            if (totalQuantities[slug]) {
              totalQuantities[slug].totalQuantity += recipe.total;
            } else {
              recipe.flower.totalQuantity = recipe.total;
              recipe.flower.value = recipe.flowerDetails
                ? recipe.flowerDetails.name
                : "";
              totalQuantities[slug] = recipe.flower;
            }
          }
        });
      });

      Object.keys(totalQuantities).forEach((k) => {
        this.plannerFields.selectedFlowerRecipes.push(totalQuantities[k]);
      });
    },
    changeFlowerList($event, recipeIndex, eventItemIndex) {
      if (
        this.plannerFields.recipes[eventItemIndex].products[recipeIndex]
          .flowerDetails != undefined
      ) {
        this.plannerFields.recipes[eventItemIndex].products[
          recipeIndex
        ].flowerDetails = {};
        this.plannerFields.recipes[eventItemIndex].products[
          recipeIndex
        ].product_name = "";
        this.$refs[
          "flower_autocomplate" + "_" + recipeIndex + "_" + eventItemIndex
        ].resetResult();
        this.plannerFields.recipes[eventItemIndex].products[
          recipeIndex
        ].quantity = 1;
        this.plannerFields.recipes[eventItemIndex].products[
          recipeIndex
        ].total = 0;
      }
      this.updateItemTotal(eventItemIndex);
    },
    flowerUpdated(search) {
      if (this.plannerFields.recipes[search.eventItemIndex] != undefined) {
        this.plannerFields.recipes[search.eventItemIndex].products[
          search.recipeIndex
        ].color_name = search.value.forEachcolor;
        this.plannerFields.recipes[search.eventItemIndex].products[
          search.recipeIndex
        ].product_name = search.value.name;
        this.plannerFields.recipes[search.eventItemIndex].products[
          search.recipeIndex
        ].flowerDetails = search.value;
      }
      if (parseInt(search.eventItemIndex) >= 0) {
        this.updateItemTotal(search.eventItemIndex);
      }
    },
    submitPlanner() {
      this.isPageLoad = false;
      if (!this.validateForm() && this.isCaptchaVerified) {
        try {
          this.plannerFields.files = this.files;
          this.isLoading = true;
          axios
            .post(
              process.env.VUE_APP_API_BASE + "auth/submitProductPlanner",
              this.plannerFields,
              {
                headers: {
                  "Content-type": "application/json;multipart/form-data",
                  Authorization: "Bearer " + localStorage.getItem("token"),
                },
              }
            )
            .then((response) => {
              if (response.data.error === true) {
                this.showFlashAlert(response.data.message, "error");
              } else {
                this.submit_ppl_GA()
                this.showFlashAlert(response.data.message, "success");
                window.location.href = "/product-planner-thank-you";
              }
              this.isLoading = false;
            });
        } catch {
          this.isLoading = false;
          console.log("Something went wrong");
        }
      } else {
        this.scrollToInvalidField();
      }
    },

    scrollToInvalidField() {
      // Without this setTimeout, an "undefined" error is thrown.
      setTimeout(() => {
        let el = document.getElementsByClassName("error-text")[0];
        let position = el.getBoundingClientRect();
        // scrolls to 20px above element
        window.scrollTo(position.left, position.top + window.scrollY - 100);
        //el.scrollIntoView();
      }, 0);
    },

    saveClearPlanner() {
      if (!this.validatePlannerOnly()) {
        try {
          this.isLoading = true;
          let url = "";
          if (!this.plannerFields.product_planner_id)
            url = process.env.VUE_APP_API_BASE + "auth/createProductPlanner";
          else url = process.env.VUE_APP_API_BASE + "auth/editProductPlanner";
          axios
            .post(url, this.plannerFields, {
              headers: {
                "Content-type": "application/json;multipart/form-data",
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            })
            .then((response) => {
              if (response.data.error === true) {
                this.showFlashAlert(response.data.message, "error");
              } else {
                this.showFlashAlert(response.data.message, "success");
                setTimeout(() => {
                  this.$router.go();
                }, 1000);
              }
            });
        } catch {
          this.isLoading = false;
          console.log("Something went wrong");
        }
      } else {
        this.scrollToInvalidField();
      }
    },

    savePlanner() {
      if (!this.validatePlannerOnly()) {
        try {
          this.isLoading = true;
          let url = "";
          if (!this.plannerFields.product_planner_id)
            url = process.env.VUE_APP_API_BASE + "auth/createProductPlanner";
          else url = process.env.VUE_APP_API_BASE + "auth/editProductPlanner";

          axios
            .post(url, this.plannerFields, {
              headers: {
                "Content-type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            })
            .then((response) => {
              if (response.data.error === true) {
                this.showFlashAlert(response.data.message, "error");
              } else {
                let ppl_ga_event = 'update_product_planner'
                if (this.plannerFields.product_planner_id) {
                  ppl_ga_event = 'create_product_planner'
                } 

                this.add_edit_ppl_GA(ppl_ga_event)
                this.plannerFields.product_planner_id = response.data.pp_id;
                this.showFlashAlert(response.data.message, "success");
                this.$router.push(
                  "/edit-product-planner/" + response.data.pp_id
                );
              }
              this.isLoading = false;
            })
            .catch((error) => {
              console.log(error);
              this.isLoading = false;
            })
            .finally(() => {
              this.isLoading = false;
            });
        } catch {
          this.isLoading = false;
          console.log("Something went wrong");
        }
      } else {
        this.scrollToInvalidField();
      }
    },

    add_edit_ppl_GA(add_edit_ppl_GA) {
      const items = [];
      if (this.plannerFields.recipes.length > 0) {
        let allRecipies = this.plannerFields.recipes;

        allRecipies.forEach((eachRecipe) => {
          eachRecipe.products.forEach((product) => {
            if (product.product_name != "") {
              items.push({
                item_id: product.flowerDetails.product_id,
                item_name: product.product_name,
                item_variant: product.color_id,
                quantity: parseInt(product.total),
              })
            }
          });
        });
      }
      
      let prodObj = {
        event_category: "product_planner",
        event_label: 'Product Planner',
        items: items
      }
      this.$gtag.event(add_edit_ppl_GA, prodObj);
    },

    submit_ppl_GA() {
      const items = [];
      if (this.plannerFields.recipes.length > 0) {
        let allRecipies = this.plannerFields.recipes;

        allRecipies.forEach((eachRecipe) => {
          eachRecipe.products.forEach((product) => {
            if (product.product_name != "") {
              items.push({
                item_id: product.flowerDetails.product_id,
                item_name: product.product_name,
                item_variant: product.color_id,
                quantity: parseInt(product.total),
              })
            }
          });
        });
      }
      
      let prodObj = {
        event_category: "submit_product_planner",
        event_label: 'Submit Product Planner',
        items: items
      }
      this.$gtag.event('submit_product_planner', prodObj);
    },

    delete_item_product_planner_GA() {
      const items = [];
      if (this.plannerFields.recipes.length > 0) {
        let allRecipies = this.plannerFields.recipes[this.recipeToDelete];

        allRecipies.products.forEach((product) => {
          if (product.product_name != "") {
            items.push({
              item_id: product.flowerDetails.product_id,
              item_name: product.product_name,
              item_variant: product.color_id,
              quantity: parseInt(product.total),
            })
          }
        });
        
      }
      
      let prodObj = {
        event_category: "delete_product_planner_item",
        event_label: 'Delete Product Planner Item',
        items: items
      }
      this.$gtag.event('delete_product_planner_item', prodObj);
    },

    delete_product_product_planner_GA(product) {
      const items = [];
      items.push({
        item_id: product.flowerDetails.product_id,
        item_name: product.product_name,
        item_variant: product.color_id,
        quantity: parseInt(product.total),
      })
      
      let prodObj = {
        event_category: "delete_product_planner_product",
        event_label: 'Delete Product Planner Product',
        items: items
      }
      this.$gtag.event('delete_product_planner_product', prodObj);
    },

    add_item_ppl_GA() {
      const items = [];
      if (this.plannerFields.recipes.length > 0) {
        let allRecipies = this.plannerFields.recipes;

        allRecipies.forEach((eachRecipe) => {
          eachRecipe.products.forEach((product) => {
            if (product.product_name != "") {
              items.push({
                item_id: product.flowerDetails.product_id,
                item_name: product.product_name,
                item_variant: product.color_id,
                quantity: parseInt(product.total),
              })
            }
          });
        });
      }
      
      let prodObj = {
        event_category: "add_product_planner_item",
        event_label: 'Add Product Planner Item',
        items: items
      }
      this.$gtag.event('add_product_planner_item', prodObj);
    },

    add_product_product_planner_GA() {
      const items = [];
      if (this.plannerFields.recipes.length > 0) {
        let allRecipies = this.plannerFields.recipes;

        allRecipies.forEach((eachRecipe) => {
          if (eachRecipe.products != undefined) {
            eachRecipe.products.forEach((product) => {
              if (product.product_name != "") {
                items.push({
                  item_id: product.flowerDetails.product_id,
                  item_name: product.product_name,
                  item_variant: product.color_id,
                  quantity: parseInt(product.total),
                })
              }
            });
          }
        });
      }
      
      let prodObj = {
        event_category: "add_product_planner_product",
        event_label: 'Add Product Planner Product',
        items: items
      }
      this.$gtag.event('add_product_planner_product', prodObj);
    },

    delete_planner_GA(pp_details) {
      const items = [];
      let planner = pp_details.details.product_planner
      if (planner != undefined && planner.recipes.length > 0) {
        let allRecipies = planner.recipes;

        allRecipies.forEach((eachRecipe) => {
          if (eachRecipe.products != undefined) {
            eachRecipe.products.forEach((product) => {
              if (product.product_name != "") {
                items.push({
                  item_id: product.flowerDetails.product_id,
                  item_name: product.product_name,
                  item_variant: product.color_id,
                  quantity: parseInt(product.total),
                })
              }
            });
          }
        });
      }
      
      let prodObj = {
        event_category: "delete_product_planner",
        event_label: 'Delete Product Planner',
        items: items
      }

      this.$gtag.event('delete_product_planner', prodObj);
    },

    

    flowerChange(searchData) {
      if (this.plannerFields.recipes[searchData.eventItemIndex] != undefined) {
        this.plannerFields.recipes[searchData.eventItemIndex].products[
          searchData.recipeIndex
        ].product_name = searchData.value.name;
        this.plannerFields.recipes[searchData.eventItemIndex].products[
          searchData.recipeIndex
        ].flowerDetails = searchData.value;
        this.updateItemTotal(searchData.eventItemIndex);
      }
    },

    savePlannerPeriodically() {
      let flowerNameExist = false;
      let triggerAutosave = false;
      let url = "";
      if (!this.plannerFields.product_planner_id)
        url = process.env.VUE_APP_API_BASE + "auth/createProductPlanner";
      else url = process.env.VUE_APP_API_BASE + "auth/editProductPlanner";

      if (this.plannerFields.event_name == "") {
        const d = new Date();
        let month = d.toLocaleString("default", { month: "long" });
        let currentDate = d.getDate();
        this.plannerFields.event_name = "Event " + month + " " + currentDate;
      }

      // check recipe entered
      if (this.plannerFields.recipes.length > 0) {
        let allRecipies = this.plannerFields.recipes;

        allRecipies.forEach((eachRecipe) => {
          flowerNameExist = false;
          if (eachRecipe.products != undefined) {
            eachRecipe.products.forEach((product) => {
              if (product.product_name != "") {
                flowerNameExist = true;
                triggerAutosave = true;
                return false;
              }
            });
          }

          if (flowerNameExist) {
            if (eachRecipe.name == "") {
              eachRecipe.name = "Item " + this.lastCounter;
              this.lastCounter++;
            }
          }
        });
      }

      if (triggerAutosave) {
        try {
          axios
            .post(url, this.plannerFields, {
              headers: {
                "Content-type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            })
            .then((response) => {
              if (response.data.error === true) {
                this.showFlashAlert(response.data.message, "error");
              } else {
                let ppl_ga_event = 'update_product_planner'
                if (this.plannerFields.product_planner_id) {
                  ppl_ga_event = 'create_product_planner'
                } 

                this.add_edit_ppl_GA(ppl_ga_event)
                this.showFlashAlert(response.data.message, "success");
                if (!this.plannerFields.product_planner_id)
                  this.plannerFields.product_planner_id = response.data.pp_id;
              }
              this.isLoading = false;
            })
            .catch((error) => {
              console.log(error);
              this.isLoading = false;
            });
        } catch {
          console.log("Something went wrong");
        }
      }
    },
  },
  watch: {
    "plannerFields.event_date": function () {
      this.fetchProductsAvailability();
    },

    colorBubble: function () {
      this.colorBubbleList = this.colorBubble;
    },

    ppl_autosave_time: function () {
      if (this.ppl_autosave_time > 1 && this.isLoggedIn) {
        this.initiate_ppl_autosave();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/local/productPlanner.scss";

.md-form {
  position: relative;
}

span.warn {
  position: absolute;
  right: 15px;
  bottom: 9px;
}
.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}
.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle;
}
body .select2-container--default .select2-selection--single {
  border: 1px solid #ced4da;
  height: 38px;
}
.select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px;
}
.select2-container .select2-selection--single {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  height: 28px;
  user-select: none;
  -webkit-user-select: none;
}
body
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  line-height: normal;
  height: 38px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
body
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  line-height: normal;
  height: 38px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}
.event-planner {
  border: 2px solid #e6e5e5;
  padding: 10px;
}
.mt_top {
  display: block;
  margin: 20px 15px 20px 0px;
}
.btn-success {
  margin-right: 5px;
}
.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.form-control {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  /* background-color: #fff; */
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.table thead th,
.table > :not(:last-child) > :last-child > * {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
  border-bottom-color: #dee2e6;
}
#btfrom {
  border: 2px solid #9f9f9f;
  border-radius: inherit;
  background: inherit;
  color: #797979;
  padding: 8px 25px;
}
#btfrom:hover {
  border: 2px solid #9f9f9f;
  background: #9f9f9f;
  color: #fff;
}
.red-asterick {
  color: red;
}
.error-text {
  scroll-margin: 50px 0 0 50px;
  color: red;
}
.popper {
  min-width: 280px !important;
  text-align: center;
}
.error-text {
  scroll-margin: 40px !important;
}
.display-contents {
  display: contents;
}
a {
  text-decoration: none;
}
:deep(.popper) {
  min-width: 281px;
  z-index: 9999;
  text-align: center;
}
table.recipe-table th:empty,
table.recipe-table td:empty {
  padding: 0;
  width: 0;
}
.info-wrapper .btn-wrap input[type="button"] + input[type="button"] {
  margin-left: 15px;
}

@media screen and (max-width: 767px) {
  .recipe-table thead {
    display: none;
  }
  .recipe-table tbody tr {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
  .recipe-table tbody tr + tr {
    border-top: 1px solid #dee2e6;
  }
  .recipe-table tbody tr td label {
    display: block;
  }
  .recipe-table tbody tr td:first-child {
    flex: 1 0 0%;
  }
  .recipe-table tbody tr td:last-child {
    order: 0;
    flex-basis: 60px;
    max-width: 60px;
  }
  .recipe-table tbody tr td:nth-child(2) {
    flex-basis: calc(100% - 60px);
    max-width: calc(100% - 60px);
    order: 1;
  }
  .recipe-table tbody tr td:nth-child(3) {
    flex-basis: 60px;
    max-width: 60px;
    order: 2;
  }
  .recipe-table tbody tr td:nth-child(4) {
    flex: 1 1 0%;
    width: auto;
    order: 2;
  }
  .recipe-table tbody tr td:nth-child(5) {
    flex: 1 1 0%;
    width: auto;
    order: 2;
    text-align: right;
  }
}
@media screen and (max-width: 575px) {
  .info-wrapper .btn-wrap input[type="button"] {
    width: 100%;
  }
  .info-wrapper .btn-wrap input[type="button"] + input[type="button"] {
    margin-top: 5px;
    margin-left: 0;
  }
}
</style>