<template>
  <section>
    <PageMetadata v-if="foundWishList" :pageInformation="wishList" />
    <div class="row">
      <modal
        v-if="deleteWishlistItemModalShow"
        :closeFn="hideDeleteWishlistItemModal"
        closeBtn="cancel"
        :submitFn="deleteDetails"
        submitBtn="ok"
      >
        Are you sure, you want to delete wish list product?
      </modal>
      <flash-alert v-model:show="flashAlert.show" :level="flashAlert.level"> 
        <span v-html="flashAlert.msg"></span>
      </flash-alert>
      <loading-overlay
        :active="isLoading"
        :is-full-page="fullPage"
        :loader="loader"
      />
      <div class="table-responsive">
        <div
          v-if="wishlistProductsCount > 0"
          class="d-flex justify-content-center align-items-center"
        >
          <template v-if="!editWishtListName.show">
            <h1>{{ wishlistName }}</h1>
            <span @click.prevent.stop="toggleNameField(true)" class="m-l-5"
              ><i class="fa-solid fa-pen-to-square"></i
            ></span>
          </template>
          <div v-else class="m-b-59 m-t-50">
            <input type="text" @keydown.enter="saveName" v-model="editWishtListName.newName" />
            <span @click.prevent.stop="saveName" class="m-l-5"
              ><i class="fa-solid fa-floppy-disk"></i
            ></span>
            <span @click.prevent.stop="toggleNameField(false)" class="m-l-5"
              ><i class="fa-solid fa-rectangle-xmark"></i
            ></span>
          </div>
        </div>
        <div v-if="wishlistProductsCount > 0">
          <div class="m-b-5 float-start">
          <move-to-quote
          v-if="wishlist_is_quotable"
          :quant="1"
          :wishlist="wishlist_id"
          btnTxt = "QUOTE ALL"
        />
          </div>
          <button
            class="m-b-5 btn btn-theme float-end"
            v-if="wishlistItemToDelete.length"
            @click.prevent="showDeleteWishlistItemModal"
          >
            Delete ({{ wishlistItemToDelete.length }})
          </button>
          <table
            v-if="wishlistProductsCount > 0"
            class="table table-striped table-bordered text-center"
          >
            <thead class="bg-grey">
              <tr>
                <th>
                  <input
                    type="checkbox"
                    :checked="
                      wishlistItemToDelete.length === wishlistItems.length
                    "
                    @change="handleWliCbx"
                  />
                </th>
                <th>Image</th>
                <th class="text-left">Product Name</th>
                <th>
                  Notify
                  <Popper
                    class="d-inline"
                    style="width: 10px"
                    :arrow="true"
                    :hover="true"
                    content="You will receive an email notification when this product is available to purchase online."
                    ><i class="fa-solid fa-circle-question"></i
                  ></Popper>
                </th>
                <th class="quoteCol">Quote</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(product, index) in wishlistItems" :key="index">
                <td>
                  <input
                    type="checkbox"
                    v-model="wishlistItemToDelete"
                    :value="product.id"
                  />
                </td>
                <td class="img-with-cbx">
                  <a :href="`/${product.seo_url}`">
                    <img
                      v-if="product.image != null"
                      :src="`${imagePath}` + product.image"
                      class=""
                      :alt="product.image"
                    />
                    <img
                      v-else
                      :src="
                        `${imagePath}` +
                        getDefaultImage(product.product_type_id)
                      "
                      class=""
                      :alt="product.product_name"
                    />
                  </a>
                </td>
                <td class="text-left">
                  <a :href="`/${product.seo_url}`">{{
                    product.product_name
                  }}</a>
                </td>
                <td>
                  <input
                    v-if="product.notify_when_available == 1"
                    checked
                    type="checkbox"
                    @click.prevent="updateNotify(index)"
                  />
                  <input
                    v-else
                    type="checkbox"
                    @click.prevent="updateNotify(index)"
                  />
                </td>
                <td>
                  <!-- v-if="wishlistItem.is_quotable" -->
                  <div class="d-flex
                            align-items-center
                            justify-content-center" v-if="product.is_quotable">
                    <input
                      type="number"
                      :min="product.quantity"
                      :step="product.quantity"
                      class="form-control m-r-5 width-70"
                      v-model="quoteQuant[product.id]"
                    />
                    <span class="product_unit" v-if="product.unit_id">
                      {{ product.unit_type }}</span
                    >
                    <select
                      v-else
                      @change="setUnitType($event, product)"
                      class="
                        form-select
                        product_unit
                        d-inline-block
                        w-auto
                        bg-transparent
                      "
                    >
                      <option
                        v-for="(fllUnitType, index) in fllUnitTypes"
                        :key="index"
                        :value="fllUnitType.id"
                        :selected="fllUnitType.id == fllUnitTypes[0].id"
                      >
                        {{ fllUnitType.unit }}
                      </option>
                    </select>
                    <add-to-quote
                      :quant="quoteQuant[product.id]"
                      :prods="[
                        {
                          ...product,
                          name: product.product_name,
                        },
                      ]"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else>Currently, there are no products for this wish list</div>
      </div>
    </div>
  </section>
</template>

<script>
import { misc } from "@/mixins";
import axios from "axios";
import Popper from "vue3-popper";
import { Modal, AddToQuote, MoveToQuote, FlashAlert, PageMetadata } from "@/components";
export default {
  name: "WishList",
  mixins: [misc],
  components: {
    Modal,
    Popper,
    AddToQuote,
    MoveToQuote,
    FlashAlert,
    PageMetadata,
  },
  data() {
    return {
      wishlistItems: [],
      wishList: {},
      foundWishList: false,
      wishlistName: "",
      wishlist_is_quotable: false,
      wishlist_id: 0,
      fllUnitTypes: JSON.parse(localStorage.getItem("fllUnitTypes")),
      loader: "bars",
      isLoading: false,
      deleteWishlistItemModalShow: false,
      wishlistItemToDelete: [],
      quoteQuant: {},
      editWishtListName: {
        show: false,
        newName: "",
      },
    };
  },
  computed: {
    wishlistProductsCount() {
      return this.wishlistItems.length;
    },
  },
  mounted() {
    this.fetchItems();
    localStorage.setItem("currentPage", "wish-list");
    this.fetchMetaDetails();
    this.emitter.on("showLoader", () => {
      this.showLoader();
    });
    this.emitter.on("hideLoader", () => {
      this.hideLoader();
    });
    this.emitter.on("a2q", (payload) => {
      this.addToQuoteAfterMath(payload);
    });

    this.emitter.on("deletedProduct", () => {
      this.hideLoader();
    });
  },
  unmounted() {
    this.emitter.off("a2q");
  },
  methods: {
    handleWliCbx(event) {
      if (event.target.checked) {
        const ids = [];
        this.wishlistItems.forEach((arr) => {
          ids.push(arr.id);
        });
        this.wishlistItemToDelete = ids;
      } else {
        this.wishlistItemToDelete = [];
      }
    },
    setUnitType(event, prod) {
      prod.unit_id_dd = parseInt(event.target.value, 10);
    },
    toggleNameField(showIt = false) {
      if (showIt) {
        this.editWishtListName.show = true;
        this.editWishtListName.newName = this.wishlistItems[0].name;
      } else {
        this.editWishtListName.show = false;
      }
    },
    saveName() {
      this.isLoading = true;
      axios
        .post(
          process.env.VUE_APP_API_BASE + "auth/renameWishList",
          {
            old_name: this.wishlistItems[0].name,
            new_name: this.editWishtListName.newName,
          },
          {
            headers: {
              "Content-type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then(() => {
          this.isLoading = false;
          window.location.href = "/wishlist/" + this.editWishtListName.newName;
        });
    },
    fetchItems() {
      this.isLoading = true;
      try {
        axios
          .post(
            process.env.VUE_APP_API_BASE + "auth/getWishlistDetails",
            {
              wishlist_name: this.$route.params.name,
            },
            {
              headers: {
                "Content-type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            this.wishlistItems = response.data.products;
            this.wishlistItems.forEach((wish) => {
              this.quoteQuant[wish.id] = wish.quantity;
            });
            if (this.wishlistItems.length > 0) {
              this.wishlistName = this.wishlistItems[0].name;
              this.wishList = {
                'name': this.wishlistName + ' Wish List',
                'no_index': 0
              };
              this.foundWishList = true;
              this.wishlist_id = this.wishlistItems[0].id;
              this.wishlist_is_quotable = response.data.is_quotable;
            }
            this.isLoading = false;
          });
      } catch {
        this.isLoading = false;
        console.log("Something went wrong");
      }
    },

    hideDeleteWishlistItemModal() {
      this.wishlistItemToDelete = [];
      this.deleteWishlistItemModalShow = false;
    },

    showDeleteWishlistItemModal() {
      this.deleteWishlistItemModalShow = true;
    },

    deleteDetails() {
      this.deleteWishlistItemModalShow = false;
      this.showLoader();
      axios
        .post(
          process.env.VUE_APP_API_BASE + "auth/deleteWishListItem",
          {
            wishlistId: this.wishlistItemToDelete,
          },
          {
            headers: {
              "Content-type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          let level = "error";
          let message = "";
          if (response.data.error == false) level = "success";
          message = response.data.message;
          let payload = { message: message, level: level };
          if (response.data.error == false) {
            this.emitter.emit("deleteFromWishlist", {});
            this.fetchItems();
          }
          this.emitter.emit("displayFlash", payload);
        });
    },
    updateNotify(rowId) {
      this.showLoader();
      axios
        .post(
          process.env.VUE_APP_API_BASE + "auth/updateWishListName",
          {
            notify: this.wishlistItems[rowId].notify_when_available ? 0 : 1,
            name: this.wishlistItems[rowId].name,
            wishlist: this.wishlistItems[rowId].id,
          },
          {
            headers: {
              "Content-type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.isLoading = false;
          let level = "error";
          let message = "";
          if (response.data.error == false) level = "success";
          message = response.data.message;
          let payload = { message: message, level: level };
          if (response.data.error == false) {
            this.emitter.emit("displayFlash", payload);
            this.$router.go();
          }
        });
    },
    showLoader() {
      this.isLoading = true;
    },
    hideLoader() {
      this.isLoading = false;
    },
    addToQuoteAfterMath(response) {
      this.triggerFlashAlert(response.msg, response.level);
    },
  },
};
</script>
<style scoped>
a {
  text-decoration: none;
}
.quoteCol {
  width: 200px;
}

span.product_unit { text-transform: capitalize; padding-top:8px; padding-left:8px; width:75px; text-align: left;}
.width-70 {width:70px;}
</style>