<template>
  <metainfo />
  <sticky-header />
  <div class="container">
    <router-view></router-view>
    <MyFooter />
  </div>
  <div class="footer-stickey-wrapper">
    <div class="container">
      <cookie-consent
        message="This website uses cookies to ensure you get the best experience on our website."
        href="/privacy-policy"
        target=""
      >
      </cookie-consent>
    </div>

    <MyBanners position="bottom" />
  </div>
  <full-footer />
  <UpdateAvailable />
</template>

<script>
import { Footer as MyFooter, StickyHeader, FullFooter } from "@/layouts";
import CookieConsent from "vue-cookieconsent-component";
import axios from "axios";
import { UpdateAvailable, MyBanners } from "@/components";
import { notifications, installPwa, loyalty } from "@/mixins";

export default {
  name: "App",
  mixins: [notifications, installPwa, loyalty],
  components: {
    MyFooter,
    StickyHeader,
    FullFooter,
    CookieConsent,
    UpdateAvailable,
    MyBanners,
  },
  data() {
    return {
      SITE_URL: process.env.VUE_APP_URL_BASE,
      SITE_NAME: localStorage.getItem("meta_title_brand"),
      bottomBanners: [],
    };
  },
  mounted() {
    this.updateSpecialProgram();
    this.emitter.on("updateSpecialProgram", () => {
      this.updateSpecialProgram();
    });
    this.fetchCustomerLoyaltyInfo();
  },
  created() {
    axios
      .get(process.env.VUE_APP_API_BASE + "auth/scripts", {
        headers: {
          "Content-type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        response.data.scripts.forEach((script) => {
          let scriptAttrs = script.other_attributes;

          if (script.src) {
            script.src = script.src.trim();
          }
          if (script.src) {
            this.pluginScript({
              type: "external",
              content: script.src,
              placement: script.placement,
              attrs: scriptAttrs,
            });
            scriptAttrs = "";
          }

          if (script.content) {
            script.content = script.content.trim();
          }
          if (script.content) {
            let type = script.type;
            if (script.type !== "noscript") {
              type = "inline";
            }

            this.pluginScript({
              type,
              content: script.content,
              placement: script.placement,
              attrs: scriptAttrs,
            });
          }
        });
      })
      .catch(() => {

      });
  },
  methods: {
    pluginScript(script) {
      let scriptElem;

      if (script.type === "inline") {
        scriptElem = document.createElement("script");
        const inlineScript = document.createTextNode(script.content);
        scriptElem.appendChild(inlineScript);
      } else if (script.type === "external") {
        scriptElem = document.createElement("script");
        scriptElem.src = script.content;
      } else if (script.type === "noscript") {
        scriptElem = document.createElement("noscript");
        const inlineScript = document.createTextNode(script.content);
        scriptElem.appendChild(inlineScript);
      }

      if (script.attrs) {
        const attrs = script.attrs.split(",");
        attrs.forEach((attr) => {
          attr = attr.split("=");
          let value = "";
          if (attr.length > 1) {
            value = attr[1].trim();

            if (['"', "'"].includes(value.charAt(0))) {
              value = value.substring(1);
            }

            const lastChar = value.charAt(value.length - 1);
            if (['"', "'"].includes(lastChar)) {
              value = value.substring(0, value.length - 1);
            }
          }
          scriptElem.setAttribute(attr[0].trim(), value);
        });
      }

      if (script.placement === "head") {
        document.head.appendChild(scriptElem);
      } else if (script.placement === "body_end") {
        document.body.appendChild(scriptElem);
      } else if (script.placement === "body_start") {
        const firstChild = document.body.firstChild;
        firstChild.parentNode.insertBefore(scriptElem, firstChild);
      }
    },
    updateSpecialProgram() {
      axios
      .get(process.env.VUE_APP_API_BASE + "auth/getSpecialsStatus", {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((response) => {
        let specials_status = response.data.specials;
        
        setTimeout(() => {
          if (specials_status) {
            let elements = document.querySelectorAll('.' + response.data.specials_class)
            if (elements.length > 0) {
              elements.forEach((element) => {
                  element.classList.remove('d-none')
              })
            }

            elements = document.querySelectorAll('.' + response.data.not_specials_class)
            if (elements.length > 0) {
              elements.forEach((element) => {
                  element.classList.add('d-none')
              })
            }
          } else {
            let elements = document.querySelectorAll('.' + response.data.not_specials_class)
            if (elements.length > 0) {
              elements.forEach((element) => {
                  element.classList.remove('d-none')
              })
            }

            elements = document.querySelectorAll('.' + response.data.specials_class)
            if (elements.length > 0) {
              elements.forEach((element) => {
                  element.classList.add('d-none')
              })
            }
          }
        }, 500)
      });
    },
  },
};
</script>
<style scoped>
  .footer-stickey-wrapper {
    position: sticky;
    bottom: 0px;
    z-index: 1000;
  }
</style>