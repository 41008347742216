<template>
  <section class="mt-4">
    <PageMetadata />
    <modal
      v-if="deleteProductModalShow"
      :closeFn="hideDeleteProductModal"
      closeBtn="cancel"
      :submitFn="voidOrder"
      submitBtn="ok"
    >
      Are you sure, you want to void Order?
    </modal>
    <loading-overlay
      :active="isLoading"
      :is-full-page="fullPage"
      :loader="loader"
    />
    <flash-alert v-model:show="flashAlert.show" :level="flashAlert.level">
      <span v-html="flashAlert.msg"></span>
    </flash-alert>
    <div id="account-profile" class="container">
      <div class="row">
        <h1 class="text-center">{{ getMetaDetails.page_h1 }}</h1>
        <div class="text-center" v-if="!!getMetaDetails.description" v-html="getMetaDetails.description"></div>
      </div>

      <div class="row mb-5">
        <div class="col-md-5">
          <div class="md-form">
            <label for="event_date" class="">From</label>
            <v-date-picker
              v-model="fromDate"
              :first-day-of-week="1"
              color="green"
              range="false"
              :masks="{ input: 'MM-DD-YYYY' }"
              trim-weeks
            >
              <template v-slot="{ inputValue, togglePopover }">
                <div @click="togglePopover()" class="input-group flex-nowrap">
                  <input :value="inputValue" class="form-control" />
                  <span class="input-group-text" id="basic-addon2"
                    ><i class="fa-solid fa-calendar-days"></i
                  ></span>
                </div>
              </template>
            </v-date-picker>
          </div>
        </div>

        <div class="col-md-5">
          <div class="md-form">
            <label for="event_date" class="">To</label>
            <v-date-picker
              v-model="toDate"
              :first-day-of-week="1"
              color="green"
              range="false"
              :masks="{ input: 'MM-DD-YYYY' }"
              trim-weeks
            >
              <template v-slot="{ inputValue, togglePopover }">
                <div @click="togglePopover()" class="input-group flex-nowrap">
                  <input :value="inputValue" class="form-control" />
                  <span class="input-group-text" id="basic-addon2"
                    ><i class="fa-solid fa-calendar-days"></i
                  ></span>
                </div>
              </template>
            </v-date-picker>
            <div v-if="errorDateRange" class="text-danger">
              Please select date range betweeen 30 days.
            </div>
          </div>
        </div>

        <div class="col-md-2 buttons">
          <button
            type="button"
            @click="fetchHistory()"
            class="btn btn-primary w-100"
            style="margin-top: 21px"
          >
            View
          </button>
        </div>
      </div>

      <div class="table-responsive">
        <div v-if="creditCount == 0">
          There are no invoice credits to display for the last 30 days.
        </div>
        <table
          v-if="creditCount > 0"
          class="table table-striped table-bordered text-center"
        >
          <thead class="bg-grey">
            <tr>
              <th>Credit #</th>
              <th>Date</th>
              <th>Invoice #</th>
              <th>Order Total</th>
              <th>Total Credit</th>
              <th>Status</th>
              <th>View</th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(credit, index) in credits" :key="index">
              <td>
                <a
                  :href="
                    `/account/credit-details/${credit.id}/` +
                    formatDateLink(`${credit.creditDate}`)
                  "
                  >{{ credit.creditNumber }}</a
                >
              </td>
              <td>{{ formatDateWithDash(credit.creditDate) }}</td>
              <td>{{ credit.orderNumber }}</td>
              <td>{{ credit.totalOrder }}</td>
              <td>{{ credit.totalCredit }}</td>
              <td>{{ credit.status }}</td>
              <td>
                <div class="d-inline-flex">
                  <Popper :arrow="true" :hover="true" content="View">
                    <a
                      :href="
                        `/account/credit-details/${credit.orderNumber}/` +
                        formatDateLink(`${credit.creditDate}`)
                      "
                      ><i class="fa-regular fa-file-lines" aria-hidden="true"></i
                    ></a>
                  </Popper>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </section>
</template>

<script>
import { misc } from "@/mixins";
import axios from "axios";
import { FlashAlert, Modal, PageMetadata } from "@/components";
import Popper from "vue3-popper";

export default {
  name: "CreditHistory",
  mixins: [misc],
  components: { FlashAlert, Modal, PageMetadata, Popper },
  data() {
    return {
      loader: "bars",
      isLoading: false,
      deleteProductModalShow: false,
      fullPage: true,
      order_id: this.$route.params.order_id,
      orderInvoiceDetails: {},
      creditCount: -1,
      errorDateRange: false,
      toDate: new Date(),
      fromDate: new Date(),
      credits: {},
    };
  },
  mounted() {
    localStorage.setItem("currentPage", "credit-history");
    this.fetchMetaDetails();
    this.emitter.on("showLoader", () => {
      this.showLoader();
    });

    var date = new Date();
    date.setDate(date.getDate() - 30);

    this.fromDate = this.formatDate(date);
    this.toDate = this.formatDate(this.toDate);
    this.emitter.on("deletedProduct", () => {
      this.isLoading = false;
    });
    this.fetchHistory();
  },

  methods: {
    showLoader() {
      this.isLoading = true;
    },

    fetchHistory() {
      this.errorDateRange = false;
      let date1 = new Date(this.toDate);
      let date2 = new Date(this.fromDate);
      let Difference_In_Time = date1.getTime() - date2.getTime();
      let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
      if (Difference_In_Days > 30) {
        this.errorDateRange = true;
        this.showFlashAlert("Date range should be less than 30 days", "error");
        return false;
      }

      this.showLoader();
      try {
        axios
          .post(
            process.env.VUE_APP_API_BASE + "auth/getCredits",
            {
              date_from: this.formatDateFromStr(this.fromDate),
              date_to: this.formatDateFromStr(this.toDate),
            },
            {
              headers: {
                "Content-type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            if (response.data.error == true) {
              this.credits = {};
              this.creditCount = 0;
              this.showFlashAlert(response.data.message, "error");
              this.isLoading = false;
            } else {
              this.credits = response.data.credits;
              this.creditCount = this.ObjectLength_Modern(this.credits);
              this.isLoading = false;
            }
          });
      } catch (ex) {
        console.log("Catch is here", ex);
        this.isLoading = false;
      }
    },

    ObjectLength_Modern(object) {
      return Object.keys(object).length;
    },

    formatDateLink(dateString) {
      if (!dateString) {
        return "";
      }
      const rawDate = new Date(dateString);
      return (
        rawDate.getFullYear() +
        "-" +
        (rawDate.getMonth() + 1) +
        "-" +
        rawDate.getDate()
      );
    },
  },
};
</script>

<style scoped>
.font-weight-bold {
  font-weight: bold;
}
.vld-overlay .vld-background {
  background: #000 !important;
  opacity: 0.5;
}
a {
  text-decoration: none;
}
</style>
