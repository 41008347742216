<template>
  <div v-if="updateExists" id="refresh-to-update" class="text-center">
    <div class="modal fade show d-block" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div @click="refreshApp" class="modal-content text-center p-3">
          <div class="modal-body m-0 p-0">
            New content available; please refresh. <i class="fa-solid fa-arrow-rotate-right"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop fade show"></div>
  </div>
</template>
<script>
import { pwa } from "@/mixins";
export default {
  mixins: [pwa],
};
</script>
<style scoped>
.modal-content:hover {
  cursor: pointer;
}
</style>