<template>
  <div id="footer-bottom" class="text-center pt-3 pb-3">
    <ScrollTop />
    <div class="container">
      <span class="text-center" style="font-size: 11px; letter-spacing: 1.5px"
        >Copyright {{ currentYear }} by {{ getCompanyName }}</span
      >
    </div>
  </div>
  <InstallPWAIOS />
</template>

<script>
import { ScrollTop, InstallPWAIOS } from "@/components";

export default {
    name: "FullFooter",
    components: { ScrollTop, InstallPWAIOS }, 
    computed: {
        getCompanyName() {
            return localStorage.getItem("company_name");
        },
        currentYear() {
            return new Date().getFullYear();
        },
    },
};
</script>