/**
 * Basic setup
 */
import { createApp } from 'vue';
import axios from 'axios';
import OneSignalVuePlugin from '@onesignal/onesignal-vue3';
// import VueEasyLightbox from 'vue-easy-lightbox'
import { createHead } from '@unhead/vue'

import App from './App.vue';
import { GA_code, OneSignalAppId, WelcomeNotificationString } from './configVars.js';
import veuxStore from './store/store.js';
import router from './router';
// axios.defaults.headers.common['X-Frame-Options'] = 'SAMEORIGIN'
// axios.defaults.headers.common['Content-Security-Policy'] = 'default-src self; img-src *; media-src youtube.com vimeo.com;'
// axios.defaults.headers.common['Strict-Transport-Security'] = 'max-age=63072000; includeSubDomains; preload'
// axios.defaults.headers.common['X-XSS-Protection'] = '1; mode=block'

/**
 * Plugins
 */
import VueGtag from 'vue-gtag';
import VCalendar from 'v-calendar';
import mitt from 'mitt';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

/**
 * Assets
 */
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'v-calendar/dist/style.css';
import './assets/css/style.scss';
import './assets/css/mayesh_style.css';
import './assets/css/custom_style.css';
import './assets/css/responsive_style.css';
import './assets/css/blog.css';
import './registerServiceWorker';
var displayNotifyButton = false;
if (localStorage.getItem('loggedinUser') !== undefined && localStorage.getItem('loggedinUser') !== '' && localStorage.getItem('loggedinUser') !== null) {
	displayNotifyButton = true;
}
const app = createApp(App)
	.use(veuxStore)
	.use(router)
	.use(createHead())
	.use(VueGtag, {
		config: { id: GA_code },
	})
	.use(VCalendar, {})
	.use(OneSignalVuePlugin, {
		appId: OneSignalAppId,
		serviceWorkerParam: {
			scope: '/onesignal/',
			path: 'onesignal/OneSignalSDKWorker.js',
		},
		serviceWorkerPath: 'onesignal/OneSignalSDKWorker.js',
		serviceWorkerUpdaterPath: '/onesignal/OneSignalSDKWorker.js',
		welcomeNotification: {
			disable: false,
			title: "Thanx for enabling notifs!",
			message: "You're officially a Mayesh insider 😊",
			url: WelcomeNotificationString
		},
		notifyButton: {
			enable: displayNotifyButton,
			position: 'bottom-left',
			background: '#f37f62',
			showCredit: false,
			text: {
				'tip.state.unsubscribed': 'Subscribe to notifications',
				'tip.state.subscribed': "You're subscribed to notifications",
				'tip.state.blocked': "You've blocked notifications",
				'message.prenotify': 'Click to subscribe to notifications',
				'message.action.subscribed': "Thanks for subscribing!",
				'message.action.resubscribed': "You're subscribed to notifications",
				'message.action.unsubscribed': "You won't receive notifications again",
				'dialog.main.title': 'Manage Site Notifications',
				'dialog.main.button.subscribe': 'SUBSCRIBE',
				'dialog.main.button.unsubscribe': 'UNSUBSCRIBE',
				'dialog.blocked.title': 'Unblock Notifications',
				'dialog.blocked.message': "Follow these instructions to allow notifications:"
			},
			colors: {
				'circle.background': '#f37f62',
				'circle.foreground': 'white',
				'badge.background': '#f37f62',
				'badge.foreground': 'white',
				'badge.bordercolor': 'white',
				'pulse.color': 'white',
				'dialog.button.background.hovering': 'rgb(77, 101, 113)',
				'dialog.button.background.active': 'rgb(70, 92, 103)',
				'dialog.button.background': '#f37f62',
				'dialog.button.foreground': 'white'
			},
		},
	});

const emitter = mitt();
app.config.globalProperties.emitter = emitter;
veuxStore.emitter = emitter;

app.component('loading-overlay', Loading);

router.isReady();

axios.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		if (error.response.status === 401 && veuxStore.getters.isLoggedIn) {
			emitter.emit('hideLoader');
			veuxStore.dispatch('doLogout');
			emitter.emit('unauth', {
				msg: 'dwdwdw'//error.response.data.message
			});
		}
		return Promise.reject(error);
	}
);

app.config.globalProperties.$filters = {
	numberFormat(value) {
		if (value == null) return '0.00';

		value = parseFloat(value);
		return value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	},
	integerNumberFormat(value) {
		if (value == null) return '0';

		value = parseFloat(value);
		return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	},
};

app.mount('#app');