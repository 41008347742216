<template>
  <section class="mt-4">
    <PageMetadata v-if="foundInvoiceCredit" :pageInformation="invoiceCredit"/>
    <loading-overlay
      :active="isLoading"
      :is-full-page="fullPage"
      :loader="loader"
    />
    <flash-alert v-model:show="flashAlert.show" :level="flashAlert.level">
      <span v-html="flashAlert.msg"></span>
    </flash-alert>
    <div id="account-profile" class="container">
      <div class="row">
        <h1 class="header px-0">
          CREDIT #: {{ invoiceDetails.creditNumber }}
        </h1>
        <p>{{ invoiceDetails.customerName }}</p>
      </div>
      <div class="row">
        <div class="col-md-6 pl-0">
          <div class="billing-wrapper mb-4">
            <div class="billing-container">
              <div class="">
                <p class="my-0">
                  <span class="font-weight-bold">Invoice #:</span>
                  {{ invoiceDetails.orderNumber }}
                </p>
                <p class="my-0">
                  <span class="font-weight-bold">Invoice Total:</span>
                  ${{ $filters.numberFormat(invoiceDetails.totalOrder) }}
                </p>
                <p class="my-0">
                  <span class="font-weight-bold">Carrier Name:</span>
                  {{ invoiceDetails.carrierName }}
                </p>
                <p class="my-0">
                  <span class="font-weight-bold">Sales Person:</span>
                  {{ invoiceDetails.salesPersonName }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 pr-0">
          <div class="billing-wrapper mb-4">
            <div class="billing-container">
              <div class="">
                <p class="my-0">
                  <span class="font-weight-bold">Credit Date:</span>
                  <template v-if="invoiceDetails.creditDate">{{ formatDateWithDash(invoiceDetails.creditDate) }}</template>
                </p>
                <p class="my-0">
                  <span class="font-weight-bold">Approved Date:</span>
                  <template v-if="invoiceDetails.approvedDate">{{ formatDateWithDash(invoiceDetails.approvedDate) }}</template>
                </p>
                <p class="my-0">
                  <span class="font-weight-bold">Total Credit:</span>
                  ${{ $filters.numberFormat(invoiceDetails.totalCredit) }}
                </p>
                <p v-if="invoiceDetails.returnedTax > 0" class="my-0">
                  <span class="font-weight-bold">Taxes Returned:</span>
                  ${{ $filters.numberFormat(invoiceDetails.returnedTax) }}
                </p>
                <p class="my-0">
                  <span class="font-weight-bold">Status:</span>
                  {{ invoiceDetails.status }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-if="invoiceDetails.details.length > 0 || invoiceDetails.detailsUnits.length > 0">
        <div class="table-responsive">
          <table class="table table-striped table-bordered text-center">
            <thead class="bg-grey">
              <tr>
                <th class="text-left">Product Name</th>
                <th class="text-left">Farm</th>
                <th>Credit Reason</th>
                <th>Units</th>
                <th>Credit Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in invoiceDetails.details" :key="index">
                <td class="text-left">{{ item.productDescription }}</td>
                <td class="text-left">{{ item.growerName }}</td>
                <td>{{ item.creditReasons }}</td>
                <td>{{ item.quantityBoxes }}</td>
                <td>${{ $filters.numberFormat(item.creditAmount) }}</td>
              </tr>
              <tr v-for="(item, index) in invoiceDetails.detailsUnits" :key="index">
                <td class="text-left">{{ item.productDescription }}</td>
                <td class="text-left">{{ item.growerName }}</td>
                <td>{{ item.creditReasons }}</td>
                <td>{{ item.totalUnits }}</td>
                <td>${{ $filters.numberFormat(item.creditAmount) }}</td>
              </tr>
            </tbody>
          </table>
          <div class="pull-right">
            <p
              class="pb-0 my-0"
              style="text-align-last: right; padding: 0.5rem"
            >
              Sub-Total: ${{
                $filters.numberFormat(invoiceDetails.totalCredit -
                invoiceDetails.returnedTax)
              }}
            </p>
            <p
              class="py-0 my-0"
              style="text-align-last: right; padding: 0.5rem"
            >
              Taxes: ${{ $filters.numberFormat(invoiceDetails.returnedTax) }}
            </p>
            <h4
              class="py-0 my-0 font-weight-bold"
              style="text-align-last: right; padding: 0.5rem"
            >
              Total: ${{ $filters.numberFormat(invoiceDetails.totalCredit) }}
            </h4>
            <div
              v-if="canVoidOrder"
              class="buttons clearfix pull-right mt-3"
              style="text-align-last: right"
            >
              <a
                href="javascript:void()"
                @click.prevent="showDeleteProductModal()"
                class="btn btn-primary"
                >Cancel Order</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { misc } from "@/mixins";
import axios from "axios";
import { FlashAlert, PageMetadata } from "@/components";
import { mapMutations } from "vuex";
export default {
  name: "CreditDetails",
  mixins: [misc],
  components: { FlashAlert, PageMetadata },
  data() {
    return {
      loader: "bars",
      isLoading: false,
      deleteProductModalShow: false,
      fullPage: true,
      creditOrderNumber: this.$route.params.credit_order_number,
      creditDate: this.$route.params.credit_date,
      invoiceDetails: {
        creditNumber: '',
        details: [],
        detailsUnits: [],
      },
      invoiceCredit: {},
      foundInvoiceCredit: false,
    };
  },
  created() {
    this.orderDetails();
  },
  mounted() {
    localStorage.setItem("currentPage", "credit-details");
    this.fetchMetaDetails();
    this.emitter.on("showLoader", () => {
      this.showLoader();
    });
    this.emitter.on("deletedProduct", () => {
      this.isLoading = false;
    });
  },

  methods: {
    ...mapMutations(["updateDynamicBreadcrumb"]),
    showLoader() {
      this.isLoading = true;
    },
    orderDetails() {
      this.showLoader();
      try {
        axios
          .post(
            process.env.VUE_APP_API_BASE + "auth/getCreditDetails",
            {
              credit_order_number: this.creditOrderNumber,
              credit_date: this.creditDate,
            },
            {
              headers: {
                "Content-type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            if (!response.data.error) {
              this.invoiceDetails = response.data.credit_details;
              this.invoiceCredit = {
                'name': 'Credit # ' + this.invoiceDetails.creditNumber,
                'no_index': 0
              };
              this.foundInvoiceCredit = true;
              this.isLoading = false;
              const crumb = [
                {
                  routeName: "Home",
                  text: '<i class="fa-solid fa-house-chimney"></i>',
                },
                {
                  routeName: "Account",
                  text: 'Account',
                },
              ];
              crumb.push({
                route: this.$route.fullPath,
                text: 'Credit Details ' + response.data.credit_details.creditNumber,
              });
              this.updateDynamicBreadcrumb(crumb);

            } else {
              this.showFlashAlert(response.data.message, "error");
              this.isLoading = false;
            }
          });
      } catch {
        this.isLoading = false;
      }
    },

    downloadPdf() {
      this.isLoading = true;
      axios({
        url: process.env.VUE_APP_API_BASE + "auth/getOrderPdf/" + this.order_id,
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        responseType: "blob",
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", "file.pdf");
        document.body.appendChild(fileLink);

        fileLink.click();
      });
      // try {
      //   axios
      //     .post(
      //     process.env.VUE_APP_API_BASE + "auth/getOrderPdf",
      //     {
      //       order_id: this.order_id
      //     },
      //     {
      //       headers: {
      //         "Content-type": "application/json",
      //         "responseType": "arrayBuffer",
      //         Authorization: "Bearer " + localStorage.getItem("token"),
      //       },
      //     },
      //     {responseType: 'arraybuffer'},

      //   )
      //     .then((response) => {
      //       var fileURL = window.URL.createObjectURL(new Blob([response.data.content.file], { type: 'application/pdf' }));
      //       var fileLink = document.createElement('a');

      //       fileLink.href = fileURL;
      //       fileLink.setAttribute('download', 'file.pdf');
      //       document.body.appendChild(fileLink);

      //       fileLink.click();
      //       this.isLoading = false;
      //     });
      // } catch {
      //   this.isLoading = false;
      // }
    },
  },
};
</script>

<style scoped>
.font-weight-bold {
  font-weight: bold;
}
.vld-overlay .vld-background {
  background: #000 !important;
  opacity: 0.5;
}
a {
  text-decoration: none;
}
</style>
