<template>
  <section class="mt-4">
    <PageMetadata v-if="foundInvoice" :pageInformation="invoice" />
    <modal
      v-if="deleteProductModalShow"
      :closeFn="hideDeleteProductModal"
      closeBtn="cancel"
      :submitFn="voidOrder"
      submitBtn="ok"
    >
      Are you sure, you want to void Order?
    </modal>
    <loading-overlay
      :active="isLoading"
      :is-full-page="fullPage"
      :loader="loader"
    />
    <flash-alert v-model:show="flashAlert.show" :level="flashAlert.level">
      <span v-html="flashAlert.msg"></span>
    </flash-alert>
    <div id="account-profile" class="container">
      <div class="row">
        <h1 class="header px-0">
          INVOICE #:
          <span v-if="orderInvoiceDetails.isEcommerce == 'Yes'">W</span
          >{{ orderInvoiceDetails.number
          }}<a href="#" @click.prevent="downloadPdf(orderId, orderInvoiceDetails.number)"
            ><i class="fa-solid fa-file-pdf-o" aria-hidden="true"></i
          ></a>
        </h1>
        <p>
          {{ orderInvoiceDetails.customerName }} ({{
            orderInvoiceDetails.customerCode
          }})
        </p>
      </div>

      <div class="row">
        <div class="col-md-6 pl-0">
          <div class="billing-wrapper mb-4">
            <div class="billing-container">
              <div class="">
                <p class="my-0">
                  <span class="font-weight-bold">PO #:</span>
                  {{ orderInvoiceDetails.customerPONumber }}
                </p>
                <p class="my-0">
                  <span class="font-weight-bold">Carrier Name:</span>
                  {{ orderInvoiceDetails.carrierName }}
                </p>
                <p class="my-0">
                  <span class="font-weight-bold">Status:</span>
                  {{ orderInvoiceDetails.status }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 pr-0">
          <div class="billing-wrapper mb-4">
            <div class="billing-container">
              <div class="">
                <p class="my-0">
                  <span class="font-weight-bold">Invoice Date:</span>
                  {{ formatDateWithDash(orderInvoiceDetails.shipDate) }}
                </p>
                <p class="my-0">
                  <span class="font-weight-bold">E-commerce:</span>
                  {{ orderInvoiceDetails.isEcommerce }}
                </p>
                <p class="my-0">
                  <span class="font-weight-bold">Total Boxes:</span>
                  {{ orderInvoiceDetails.totalBoxes }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 pl-0">
          <div class="billing-wrapper mb-4">
            <h2 class="sub-header pb-0">Bill To</h2>
            <div class="billing-container">
              <div class="">
                <p class="my-0">
                  {{ orderInvoiceDetails.customerName }} <br />
                  {{ orderInvoiceDetails.billAddress }} <br />{{
                    orderInvoiceDetails.billCity
                  }}, {{ orderInvoiceDetails.billState }},
                  {{ orderInvoiceDetails.billCountry }},
                  {{ orderInvoiceDetails.billZipCode }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 pl-0 pr-0">
          <div class="billing-wrapper mb-4">
            <h2 class="sub-header pb-0">Ship To</h2>
            <div class="billing-container">
              <div class="">
                <p class="my-0">
                  {{ orderInvoiceDetails.customerName }} <br />
                  {{ orderInvoiceDetails.shipAddress }} <br />{{
                    orderInvoiceDetails.shipCity
                  }}, {{ orderInvoiceDetails.shipState }},
                  {{ orderInvoiceDetails.shipCountry }},
                  {{ orderInvoiceDetails.shipZipCode }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row" v-if="orderInvoiceDetails.details.length > 0">
        <div class="table-responsive">
          <table class="table table-striped table-bordered text-center">
            <thead class="bg-grey">
              <tr>
                <th class="text-left">Product Name</th>
                <th class="text-left">Farm</th>
                <th>Boxes</th>
                <th>Total Units</th>
                <th>Landed Price</th>
                <th>Amount</th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="(item, index) in orderInvoiceDetails.details"
                :key="index"
              >
                <td class="text-left">{{ item.productDescription }}</td>
                <td class="text-left">{{ item.growerName }}</td>
                <td>{{ item.totalBoxes }} {{ item.boxType }}</td>
                <td>{{ item.totalUnits }}</td>
                <td>${{ $filters.numberFormat(item.unitPrice) }}</td>
                <td>${{ $filters.numberFormat(item.amount) }}</td>
              </tr>
            </tbody>
          </table>

          <div class="pull-right">
            <p
              class="pb-0 my-0"
              style="text-align-last: right; padding: 0.5rem"
            >
              Sub-Total: ${{ $filters.numberFormat(orderInvoiceDetails.subTotal) }}
            </p>
            <p
              class="py-0 my-0"
              style="text-align-last: right; padding: 0.5rem"
            >
              Additional Charges: ${{ $filters.numberFormat(orderInvoiceDetails.additionalCharges) }}
            </p>
            <p
              class="py-0 my-0"
              style="text-align-last: right; padding: 0.5rem"
            >
              Taxes: ${{ $filters.numberFormat(orderInvoiceDetails.taxes) }}
            </p>
            <h4
              class="py-0 my-0 font-weight-bold"
              style="text-align-last: right; padding: 0.5rem"
            >
              Total: ${{ $filters.numberFormat(orderInvoiceDetails.total) }}
            </h4>
            <div
              v-if="canVoidOrder"
              class="buttons clearfix pull-right mt-3"
              style="text-align-last: right"
            >
              <a
                href="javascript:void()"
                @click.prevent="showDeleteProductModal()"
                class="btn btn-primary"
                >Cancel Order</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { misc } from "@/mixins";
import axios from "axios";
import { FlashAlert, Modal, PageMetadata } from "@/components";
import { mapMutations } from "vuex";

export default {
  name: "OrderDetails",
  mixins: [misc],
  components: { FlashAlert, Modal, PageMetadata },
  data() {
    return {
      loader: "bars",
      isLoading: false,
      deleteProductModalShow: false,
      fullPage: true,
      orderId: this.$route.params.order_id,
      orderInvoiceDetails: {
        details: {}
      },
      canVoidOrder: false,
      invoice: {},
      foundInvoice: false,
    };
  },
  created() {
    this.orderDetails();
  },
  mounted() {
    localStorage.setItem("currentPage", "order-details");
    this.fetchMetaDetails();
    this.emitter.on("showLoader", () => {
      this.showLoader();
    });
    this.emitter.on("deletedProduct", () => {
      this.isLoading = false;
    });
  },

  methods: {
    ...mapMutations(["updateDynamicBreadcrumb"]),
    hideDeleteProductModal() {
      this.deleteProductModalShow = false;
    },

    showDeleteProductModal() {
      this.deleteProductModalShow = true;
    },

    scrollToMessage() {
      // Without this setTimeout, an "undefined" error is thrown.
      setTimeout(() => {
        let el = this.$el.getElementsByClassName("alert-success")[0];
        el.scrollIntoView();
      }, 0);
    },

    voidOrder() {
      this.hideDeleteProductModal();
      this.isLoading = true;
      try {
        axios
          .post(
            process.env.VUE_APP_API_BASE + "auth/voidOrder",
            {
              order_id: this.orderId,
            },
            {
              headers: {
                "Content-type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            //this.showFlashAlert(response.data, "success");
            //this.scrollToMessage();
            let level = "error";
            let message = "";
            if (response.data.error == false) level = "success";
            message = response.data.message;
            let payload = { message: message, level: level };
            this.isLoading = false;
            this.emitter.emit("displayFlash", payload);
            this.$router.push({ name: "Account" });
          });
      } catch {
        this.isLoading = false;
      }
    },

    showLoader() {
      this.isLoading = true;
    },
    orderDetails() {
      this.showLoader();
      try {
        axios
          .post(
            process.env.VUE_APP_API_BASE + "auth/getOrderDetails",
            {
              order_id: this.orderId,
            },
            {
              headers: {
                "Content-type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            if (!response.data.error) {
              this.orderInvoiceDetails = response.data.order_details;
              this.invoice = {
                'name': 'Invoice # ' + (this.orderInvoiceDetails.isEcommerce == 'Yes' ? 'W' : '') + this.orderInvoiceDetails.number,
                'no_index': 0
              };
              this.foundInvoice = true;
              let site_settings = JSON.parse(
                localStorage.getItem("site_settings")
              );
              if (
                (this.orderInvoiceDetails.status == "Draft" ||
                  this.orderInvoiceDetails.status == "Pending Approval") &&
                this.orderInvoiceDetails.status != "Voided" &&
                site_settings.allow_voiding_orders == 1
              )
              this.canVoidOrder = true;
              const crumb = [
                {
                  routeName: "Home",
                  text: '<i class="fa-solid fa-house-chimney"></i>',
                },
                {
                  routeName: "Account",
                  text: 'Account',
                },
              ];
              let orderNumber = ''
              if (response.data.order_details.isEcommerce)
                orderNumber = 'W' + response.data.order_details.number
              else
                orderNumber = response.data.order_details.number
              crumb.push({
                route: this.$route.fullPath,
                text: 'Order Details ' + orderNumber,
              });
              this.updateDynamicBreadcrumb(crumb);

              this.isLoading = false;
            } else {
              this.showFlashAlert(response.data.message, "error");
              this.isLoading = false;
            }
          });
      } catch {
        this.isLoading = false;
      }
    },

    downloadPdf(orderId, invoiceNumber) {
      this.isLoading = true;

      axios
        .post(
          `${process.env.VUE_APP_API_BASE}auth/getOrderPdf`,
          {
            order_id: orderId,
          },
          {
            headers: {
              "Content-type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
            responseType: "arraybuffer",
            dataType: "blob",
          }
        )
        .then((responseBlob) => {
          if (responseBlob.headers['content-type'] === 'application/json'){
            let response = JSON.parse(String.fromCharCode.apply(null, new Uint8Array(responseBlob.data)));
            if (response.error) {
              let message = response.message;
              let payload = { message: message, level: 'error' };
              this.emitter.emit("displayFlash", payload);
            }
          } else {
            const file = new Blob([responseBlob.data], { type: "application/pdf" });
            const fileURL = URL.createObjectURL(file);
            let fileName = "invoice #" + invoiceNumber;
            const link = document.createElement("a");
            link.href = fileURL;
            link.download = `${fileName}.pdf`;
            link.click();
          }
          this.isLoading = false;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped>
.font-weight-bold {
  font-weight: bold;
}
.vld-overlay .vld-background {
  background: #000 !important;
  opacity: 0.5;
}
a {
  text-decoration: none;
}
</style>
