<template>
  <v-date-picker
    :key="key"
    v-model="dateModel"
    :first-day-of-week="1"
    color="green"
    :masks="{ input: 'MM-DD-YYYY' }"
    trim-weeks
    :available-dates="availableDates"
    :min-date="minDate"
    :max-date="maxDate"
    :disabled-dates="disabledDates"
    :timezone="timezone"
    :minute-increment="minuteIncrement" 
  >
    <template v-slot="{ inputValue, togglePopover }">
      <div @click="togglePopover()" class="input-group flex-nowrap">
        <input :value="inputValue" placeholder="date" class="form-control" />
        <label v-if="label" class="control-label"> <span v-if="is_required" class="text-danger"> * </span>{{ label }} </label>
        <span class="input-group-text" id="basic-addon2"><i class="fa-solid fa-calendar-days"></i></span>
      </div>
    </template>
  </v-date-picker>
</template>

<script>
import { misc } from "@/mixins";
import { Dater } from "@/classes";

export default {
  name: "DatePicker",
  emits: ["update:dateProp", "pickerDateChanged"],

  mixins: [misc],
  props: {
    availableDates: {
      type: [Array, Object],
      default() {
        return [];
      },
    },
    minDate: {
      type: String,
      default: "",
    },
    maxDate: {
      type: String,
      default: "",
    },
    disabledDates: {
      type: Array,
      default() {
        return [];
      },
    },
    dateProp: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },

    is_required: {
      type: Boolean,
      default: false,
    },
    mode: {
      default: "date", //date, time, datetime
      type: String,
    },
    timezone: {
      type: String,
      default: undefined,
    },
    minuteIncrement: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      key: 0,
    };
  },
  computed: {
    dateModel: {
      get: function () {
        return this.appendTimeForBrowser(this.dateProp);
      },
      set: function (n) {
        if (n) {
          if (typeof n.getMonth === "function") {
            if (this.mode === "date") {
              n = this.formatToDb(n);
            } else if (this.mode === "datetime") {
              n = Dater.toDbDateTime(n);
            }
          }

          this.$emit("update:dateProp", n);

          this.processDateChange();
        } else {
          this.key++;
        }
      },
    },
  },
  methods: {
    processDateChange() {
      this.$emit("pickerDateChanged", this.dateModel);
    },
  },
};
</script>

<style scoped>
.form-control {
  border-width: 1px;
}
</style>