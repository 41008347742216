<template>
  <template v-if="(isLAL || isFDB || (isSBL && prod.unlimited_quantity == 0)) && prod.qty <= 0 "></template>
  <template v-else>
    <div class="quantity" :class="{ 'w_mob_80': min_qty > 1, 'w_mob_100': min_qty == 1}">
      <input
        v-if="isFFS || isDDB || isHOL|| isMMT || (isSBL && prod.unlimited_quantity == 1)"
        type="number"
        v-bind:min="min_qty"
        v-bind:step="inc_qty"
        v-model.number="desiredQuant"
        class="form-control"
      />
      <input
        v-else
        type="number"
        :max="max"
        v-bind:min="min_qty"
        v-bind:step="inc_qty"
        v-model.number="desiredQuant"
        class="form-control"
      />
    </div>
  </template>
    
  <div class="qty-info" v-if="prod.min_quantity > 1"> 
    <div class="popper_margin_cls tool-tip">
      <Popper v-if="prod.min_quantity > 1" :arrow="true" :hover="true" :content="minimum_message" style="margin: 0px">
        <i class="fa-solid fa-circle-info"></i>
      </Popper>
    </div>
  </div>
  <div class="w-100 d-block d-md-none"></div>
  <template v-if="(isLAL || isFDB || (isSBL && prod.unlimited_quantity == 0)) && prod.qty <= 0 ">
    <span class="sold-out-label">SOLD OUT</span>
    <Popper :arrow="true" :hover="true" content="Send Request to Order" style="margin: 0px;">
      <button @click="showRequestFdbProductForm" class="btn btn-theme ms-3 m_mob_100">
        <i class="fa-solid fa-paper-plane"></i>
      </button>
    </Popper>
    <fdb-request-product-form  v-if="displayRequestFdbProductForm"
    :closeFn="hideRequestFdbProductForm" :deliveryDate="deliveryDate" :minDate="minDate" :maxDate="maxDate" :disabledDates="disabledDates" :product="prod"></fdb-request-product-form>
    </template>
  <template v-else>
    <button @click="addToCart" class="btn btn-theme ms-3 m_mob_100">
      <i v-if="btnTypeIcon" class="fa-solid fa-cart-shopping" aria-hidden="true"></i>
      <label v-else aria-hidden="true">Add To Cart</label>
    </button>
  </template>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import axios from "axios";
import Popper from "vue3-popper";
import FdbRequestProductForm  from "./FdbRequestProductForm.vue";

export default {
  name: "AddToCart",
  props: {
    min: {
      type: Number,
      default: 1,
    },
    max: {
      type: Number,
      default: 1,
    },
    prod: {
      type: Object,
      required: true,
    },
    deliveryDate: {
      type: String,
      required: true,
    },
    btnTypeIcon: {
      default: true,
    },
    minDate: {
      type: String,
      required: false,
    },
    maxDate: {
      type: String,
      required: false,
    },
    disabledDates: {
      type: Array,
      required: false,
    },
  },
  components: {
    Popper,
    FdbRequestProductForm,
  },
  data() {
    return {
      displayRequestFdbProductForm: false,
      desiredQuant: this.prod.min_quantity,
      isFFS: false,
      isMMT: false,
      isDDB: false,
      isHOL: false,
      isMDB: false,
      isLAL: false,
      isFDB: false,
      isSBL: false,
      mmt_id: 0,
      ffs_id: 0,
      ddb_id: 0,
      hol_id: 0,
      mdb_id: 0,
      lal_id: 0,
      fdb_id: 0,
      sbl_id: 0,
      min_message: '',
      new_min: 1,
      
    };
  },
  computed: {
    ...mapState(["cartMeta", "cart"]),
    minimum_message() {
      return this.min_message + ' ' + this.prod.min_quantity;
    },

    min_qty() {
      return parseInt(this.prod.min_quantity);
    },

    inc_qty() {
      return parseInt(this.prod.inc_quantity);
    },
  },
  mounted() {
    let siteSettings = JSON.parse(localStorage.getItem("site_settings"));
    this.min_message = siteSettings.minimum_quantity_message;
    let shop_by_list_id = siteSettings.shop_by_list_id;
    let mass_markets_id = siteSettings.mass_markets_id;
    let flower_future_id = siteSettings.flower_futures_id;
    let dutch_direct_boxlots_id = siteSettings.dutch_direct_boxlots_id;
    let holiday_holland_id = siteSettings.holiday_holland_id;
    let miami_direct_boxlots_id = siteSettings.miami_direct_boxlots_id;
    let farm_direct_boxlots_id = siteSettings.farm_direct_boxlots_id;
    let live_local_id = siteSettings.live_local_id;
    this.sbl_id = shop_by_list_id;
    this.ffs_id = flower_future_id;
    this.ddb_id = dutch_direct_boxlots_id;
    this.hol_id = holiday_holland_id;
    this.mdb_id = miami_direct_boxlots_id;
    this.fdb_id = farm_direct_boxlots_id;
    this.live_local_id = live_local_id

    if (this.prod.programtypeId == mass_markets_id) this.isMMT = true;
    if (this.prod.programtypeId == flower_future_id) this.isFFS = true;
    if (this.prod.programtypeId == dutch_direct_boxlots_id) this.isDDB = true;
    if (this.prod.programtypeId == holiday_holland_id) this.isHOL = true;
    if (this.prod.programtypeId == miami_direct_boxlots_id) this.isMDB = true;
    if (this.prod.programtypeId == farm_direct_boxlots_id) this.isFDB = true;
    if (this.prod.programtypeId == live_local_id) this.isLAL = true;
    if (this.prod.programtypeId == shop_by_list_id) this.isSBL = true;
  },
  methods: {
    //...mapMutations({ addToCart_s: "addToCart" }),
    ...mapActions(["fetchCartAndMeta"]),
    ...mapGetters(["loggedinUserDetails"]),
    

    addToCart() {
      let siteSettings = JSON.parse(localStorage.getItem("site_settings"));
      let shop_by_list_id = siteSettings.shop_by_list_id;
      let mass_markets_id = siteSettings.mass_markets_id;
      let flower_future_id = siteSettings.flower_futures_id;
      let dutch_direct_boxlots_id = siteSettings.dutch_direct_boxlots_id;
      let holiday_holland_id = siteSettings.holiday_holland_id;
      let customerDetails = JSON.parse(localStorage.getItem("loggedinUser"));

      if (
        this.prod.programtypeId != mass_markets_id &&
        this.prod.programtypeId != flower_future_id &&
        this.prod.programtypeId != dutch_direct_boxlots_id && 
        this.prod.programtypeId != holiday_holland_id && 
        (this.prod.programtypeId == shop_by_list_id && this.prod.unlimited_quantity == 0)
      ) {
        if (!this.desiredQuant) {
          this.emitter.emit("a2c", {
            status: 0,
            msg: "Please input the quantity.",
          });
          return;
        }

        if (this.desiredQuant > this.max) {
          this.emitter.emit("a2c", {
            status: 0,
            msg: "The product you have added to the cart exceeds the available quantity.",
          });
          return;
        }

        
      }

      if (this.prod.min_quantity > 1 && !this.isMDB && !this.isDDB && !this.isHOL) {
        //Need to check minimum quantity validation
        if (this.prod.min_quantity > this.desiredQuant) {
          this.desiredQuant = this.prod.min_quantity
          this.emitter.emit("a2c", {
            status: 0,
            msg: `You need to add at least ${this.prod.min_quantity} products to meet the minimum requirements.`,
          });
          return;
        }
      }
        
      if (this.prod.inc_quantity > 1 && !this.isMDB && !this.isDDB && !this.isHOL) {
        let reminder = this.desiredQuant % this.prod.inc_quantity;
        if (reminder > 0) {
          this.desiredQuant = this.desiredQuant - reminder + this.prod.inc_quantity
          this.emitter.emit("a2c", {
            status: 0,
            msg: `Quantities need to be in groups of ${this.prod.inc_quantity}. Please try again.`,
          });
          return;
        }
      }

      this.emitter.emit("showLoader");
      axios
        .post(
          `${process.env.VUE_APP_API_BASE}auth/addToCart`,
          {
            product: {
              product_id: this.prod.product_id,
              upc: this.prod.upc,
              product_name: this.prod.product_name,
              program_id: this.prod.programtypeId,
              inventory_id: this.prod.inventory_id,
              shopping_list_id: this.prod.shopping_list_id,
              hol_holiday_id: this.prod.hol_holiday_id,
              order_item_id: this.prod.mainOrderItemId,
              delivery_date: this.deliveryDate,
              quantity: this.desiredQuant,
              farm_id: this.prod.farm_id,
              cost: this.prod.cost,
              landed_cost: this.prod.main_landed_cost,
              mmt_origin_id: this.prod.mmt_origin_id,
              retail_price: this.prod.retail_price,
              aging: this.prod.aging,
              is_special: this.prod.is_special,
              box_id: this.prod.box_type_id,
              box_unit_id: this.prod.box_unit_id,
              unit_count: this.prod.unit_count,
              unit_price: this.prod.price_per_unit,
              stem_price: this.prod.price_per_stem,
              sold_by_unit_id: this.prod.sold_by_unit_id,
              priced_by_unit_id: this.prod.priced_by_unit_id,
              country_id: this.prod.country_id,
              is_sales_rep: customerDetails.is_sales_rep,
              is_e_sales: customerDetails.is_e_sales,
              cube: this.prod.cube,
              cube_rate: this.prod.cube_rate,
              freight: this.prod.freight,
              markup: this.prod.markup,
              is_k2k: this.prod.is_k2k,
              add_freight_to_cost: this.prod.add_freight_to_cost,
            },
          }, // the data to post
          {
            headers: {
              "Content-type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then(({ data }) => {
          if (data.error == true) {
            this.emitter.emit("a2c", {
              status: 0,
              msg: data.message,
              level: "error",
            });
            this.fetchCartAndMeta();
            this.emitter.emit("hideLoader");
          } else {
            this.addToCartGAnalytics();
            /* this.addToCart_s({
              id: this.prod.product_id,
              quantity: this.desiredQuant,
            }); */
            this.desiredQuant = this.min_qty;
            this.emitter.emit("a2c", {
              status: 1,
              msg: data.message,
              level: "success",
            });
            this.fetchCartAndMeta();
            this.emitter.emit("hideLoader");
          }
        });
    },

    addToCartGAnalytics() {
      // const totalPrice = this.desiredQuant * this.prod.price_per_unit;
      let productObj = {
        event_category: "purchase",
        event_label: 'add to cart',
        item_id: this.prod.product_id,
        item_name: this.prod.name,
        affiliation: this.prod.farm_name,
        item_list_id: this.prod.programtypeId,
        item_list_name: this.prod.programtypeName,
        item_category: this.prod.category_name,
        item_category2: this.prod.variety_name,
        item_variant: this.prod.color_name,
        item_category3: this.prod.grade_name,
        quantity: parseInt(this.desiredQuant),
        price: parseFloat(this.prod.price_per_unit),
        currency: "USD",
      };
      this.$gtag.event("add_to_cart", productObj);
    },
    showRequestFdbProductForm() {
      this.displayRequestFdbProductForm = true;
    },

    hideRequestFdbProductForm() {
      this.displayRequestFdbProductForm = false;
    },
  },
  watch: {
    "prod.programtypeId": function () {
      this.isDDB = false;
      this.isFFS = false;
      this.isLAL = false;
      this.isFDB = false;
      this.isSBL = false;

      if (this.prod.programtypeId == this.ffs_id) this.isFFS = true;
      else if (this.prod.programtypeId == this.ddb_id) this.isDDB = true;
      else if (this.prod.programtypeId == this.hol_id) this.isHOL = true;
      else if (this.prod.programtypeId == this.lal_id) this.isLAL = true;
      else if (this.prod.programtypeId == this.fdb_id) this.isFDB = true;
      else if (this.prod.programtypeId == this.sbl_id) this.isSBL = true;
            
    },
    "prod.min_quantity": function () {
        this.desiredQuant = this.prod.min_quantity
    }
  },
};
</script>
<style>
.small-textbox {
  width: 80px !important;
}
.qty-info { 
  min-width: 35px; 
}
.popper_margin_cls { 
  margin: 0px;
}

span.sold-out-label {
  font-size: 15px;
  color: #f48062;
  text-transform: uppercase;
  text-align: center;
  height: 0px;
  display: inline-table;
}

span.sold-out-label+div.inline-block{
    border: 0px !important;
}

.signle-product-qty-wrapper .sold-out-label {
    margin-right: auto !important;
}

@media only screen and (max-width: 375px) {
  .qty-info { max-height: 35px; overflow: hidden;}
  .quantity { padding-bottom: 5px;}
  .popper_margin_cls {margin-top:-8px}
  .m_mob_100 {width: 100%;}
  .w_mob_80.quantity input {width: 100% !important;}
  .w_mob_80.quantity {width: 70% !important;}
  .w_mob_100.quantity {width: 100% !important;}
  .w_mob_100.quantity input{width: 100% !important;}
}

@media only screen and (min-width: 376px) and (max-width: 390px) {
  .qty-info { max-height: 35px; overflow: hidden;}
  .quantity { padding-bottom: 5px;}
  .popper_margin_cls {margin-top:-8px}
  .m_mob_100 {width: 100%;}
  .w_mob_80.quantity input {width: 100% !important;}
  .w_mob_80.quantity {width: 72% !important;}
  .w_mob_100.quantity {width: 100% !important;}
  .w_mob_100.quantity input{width: 100% !important;}
}

@media only screen and (min-width: 391px) and (max-width: 539px) {
  .qty-info { max-height: 35px; overflow: hidden;}
  .quantity { padding-bottom: 5px;}
  .popper_margin_cls {margin-top:-8px}
  .m_mob_100 {width: 100%;}
  .w_mob_80.quantity input {width: 100% !important;}
  .w_mob_80.quantity {width: 74% !important;}
  .w_mob_100.quantity {width: 100% !important;}
  .w_mob_100.quantity input{width: 100% !important;}
}

@media only screen and (min-width: 540px) and (max-width: 600px) {
  .qty-info { max-height: 35px; overflow: hidden;}
  .quantity { padding-bottom: 5px;}
  .popper_margin_cls {margin-top:-8px}
  .m_mob_100 {width: 100%;}
  .w_mob_80.quantity input {width: 100% !important;}
  .w_mob_80.quantity {width: 81% !important;}
  .w_mob_100.quantity {width: 100% !important;}
  .w_mob_100.quantity input{width: 100% !important;}
}

@media only screen and (min-width: 601px) and (max-width: 767px) {
  .qty-info { max-height: 35px; overflow: hidden;}
  .quantity { padding-bottom: 5px;}
  .popper_margin_cls {margin-top:-8px}
  .m_mob_100 {width: 100%;}
  .w_mob_80.quantity input {width: 100% !important;}
  .w_mob_80.quantity {width: 83% !important;}
  .w_mob_100.quantity {width: 100% !important;}
  .w_mob_100.quantity input{width: 100% !important;}
}

@media only screen and (min-width: 767px) and (max-width: 820px) {
  .qty-info { max-height: 35px; overflow: hidden;}
  .w_mob_100.quantity {margin-right: 15px;}

}

@media only screen and (min-width: 821px) and (max-width: 920px) {
  .qty-info { max-height: 35px; overflow: hidden;}
  .w_mob_100.quantity {margin-right: 15px;}

}
</style>