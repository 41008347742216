<template>
  <PageMetadata />
  <section>
    <section class="mt-4">
      <div class="container">
        <modal v-if="deleteProductModalShow" :closeFn="hideDeleteProductModal" closeBtn="cancel" :submitFn="removeProduct" submitBtn="ok">
          Are you sure, you want to delete product?
        </modal>
        <loading-overlay :active="isLoading" is-full-page="true" loader="bars"/>
        <flash-alert v-model:show="flashAlert.show" :level="flashAlert.level">
          <span v-html="flashAlert.msg"></span>
        </flash-alert>
        <div class="mb-5">
          <h1 class="pb-0 mb-4 px-0">{{ getMetaDetails.page_h1 }}</h1>
          <p v-if="!isLoggedIn" class="font-weight-bold pb-3">
            NOTE: To expedite your quote, please <a href="/login">log in</a>.
          </p>
          <component :is="'style'" v-if="getMetaDetails.additional_style != null && getMetaDetails.additional_style != ''">
            {{ getMetaDetails.additional_style }}
          </component>
          <div class="content_below_heading" v-if="!!getMetaDetails.description" v-html="getMetaDetails.description"></div>
        </div>
      </div>
    </section>
    <section class="" id="eventplanner-page">
      <div class="container" v-if="quoteListsCount > 0">
        <div class="product-wrapper">
          <div class="card product-card border-0">
            <div class="card-header bg-grey d-none d-lg-block text-white">
              <div class="row mx-0">
                <div class="col-auto product-img-wrap"></div>
                <div class="col product-name pl-0">Product Name</div>
                <div class="col col-lg-auto quantity">Quantity</div>
                <div class="col col-lg-auto lot-size">Unit Type</div>
                <div class="col-auto action">Remove</div>
              </div>            
            </div>
            <div class="card-body position-relative" v-for="(quote, index) in quoteLists" :key="index">
              <div class="row no-gutters">
                <div class="col-auto
                  product-img-wrap
                  position-relative
                  no-overflow">
                  <router-link
                    class="p-0 w-auto m-0"
                    :to="`/${quote.prodSeo}`"
                  >
                    <img
                      v-if="quote.prodImage"
                      :src="`${imagePath}` + quote.prodImage"
                      class="img-thumbnail"
                    />
                    <img v-else
                      :src="`${imagePath}` + getDefaultImage(quote.prodTypeId)"
                      class="img-thumbnail"
                      :alt="quote.name"
                    />
                  </router-link>
                </div>
                <div class="col product-name pl-0">
                  <router-link :to="`/${quote.prodSeo}`">{{ quote.prodName }}</router-link>
                </div>
                <div class="w-100 d-block d-lg-none"></div>
                <div class="col-auto quantity text-right">
                  <div>
                    <label class="d-block d-lg-none text-center text-lg-start">Quantity</label>
                    <update-quote :product="quote" :prodId="index" />
                  </div>
                </div>
                <div class="col-6 col-md col-lg-auto lot-size">
                  <div>
                    <label class="d-block d-lg-none">Unit Type</label>
                    <span class="unit_name">{{ quote.prodUnit }}</span>
                  </div>
                </div>
                <div class="col-6 col-md-auto action text-right">
                  <div>
                    <label class="d-none d-md-block d-lg-none"
                      >&nbsp;</label
                    >
                    <button
                      class="btn"
                      href="#"
                      @click.prevent="showDeleteProductModal(index)"
                    >
                      <i class="fa-solid fa-trash-can"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="border-form mt-3 p-4 p-md-5">
          <form
            id="contact-form"
            enctype="multipart/form-data"
            name="contact-form"
            method="POST"
            class="needs-validation"
            novalidate=""
          >
            <div class="row mb-4">
              <div class="col-md-4">
                <p>Expected Delivery Date:</p>
              </div>
              <div class="col-md-4">
                <v-date-picker
                v-model="plannerFields.delivery_date"
                :first-day-of-week="1"
                color="green"
                range="false"
                :masks="{ input: 'MM-DD-YYYY' }"
                trim-weeks
                :minDate="minDate" 
                :maxDate="maxDate" 
                :disabledDates="disabledDates"
              >
                <template v-slot="{ inputValue, togglePopover }">
                  <div
                    @click="togglePopover()"
                    class="input-group flex-nowrap"
                  >
                    <input :value="inputValue" class="form-control" />
                    <span class="input-group-text" id="basic-addon2"
                      ><i class="fa-solid fa-calendar-days"></i
                    ></span>
                  </div>
                </template>
              </v-date-picker>
                <span
                  class="text-danger"
                  v-if="
                    plannerFields.errors && plannerFields.errors.deliveryDate
                  "
                  >Please select delivery date.</span
                >
              </div>
            </div>
            <div v-bind:class="isLoggedIn ? 'row mb-4 d-none' : 'row mb-4'">
              <div class="col-md-4">
                <p>Name:</p>
              </div>
              <div class="col-md-4">
                <input
                  type="text"
                  v-model="plannerFields.name"
                  class="form-control"
                  :disabled="isLoggedIn && plannerFields.name != null"
                  id="cust_name"
                  required=""
                />
                <span
                  class="text-danger"
                  v-if="plannerFields.errors && plannerFields.errors.name"
                  >Please enter valid name.</span
                >
              </div>
            </div>
            <div v-bind:class="isLoggedIn ? 'row mb-4 d-none' : 'row mb-4'">
              <div class="col-md-4">
                <p>Company:</p>
              </div>
              <div class="col-md-4">
                <input
                  type="text"
                  v-model="plannerFields.company_name"
                  class="form-control"
                  name="comp_name"
                  :disabled="isLoggedIn && plannerFields.company_name != null"
                  id="comp_name"
                  required=""
                />
                <span
                  class="text-danger"
                  v-if="
                    plannerFields.errors && plannerFields.errors.company_name
                  "
                  >Please enter valid company name.</span
                >
              </div>
            </div>
            <div v-bind:class="isLoggedIn ? 'row mb-4 d-none' : 'row mb-4'">
              <div class="col-md-4">
                <p>Phone:</p>
              </div>
              <div class="col-md-4">
                <input
                  type="text"
                  v-model="plannerFields.phone"
                  class="form-control"
                  :disabled="isLoggedIn"
                  name="cust_phone"
                  id="cust_phone"
                />
              </div>
            </div>
            <div v-bind:class="isLoggedIn ? 'row mb-4 d-none' : 'row mb-4'">
              <div class="col-md-4">
                <p>Address:</p>
              </div>
              <div class="col-md-4">
                <!---->
                <input
                  type="text"
                  v-model="plannerFields.address"
                  class="form-control"
                  :disabled="isLoggedIn"
                  name="cust_address"
                  id="cust_address"
                />
              </div>
            </div>
            <div v-bind:class="isLoggedIn ? 'row mb-4 d-none' : 'row mb-4'">
              <div class="col-md-4">
                <p>City:</p>
              </div>
              <div class="col-md-4">
                <input
                  type="text"
                  :disabled="isLoggedIn"
                  v-model="plannerFields.city"
                  class="form-control"
                  name="cust_city"
                  id="cust_city"
                />
                <span
                  class="error_msg"
                  id="error_cust_city"
                  style="color: red"
                ></span>
              </div>
            </div>
            <div v-bind:class="isLoggedIn ? 'row mb-4 d-none' : 'row mb-4'">
              <div class="col-md-4">
                <p>State:</p>
              </div>
              <div class="col-md-4">
                <!--<input type="text" class="form-control" name="cust_state" id="cust_state" value=""  />-->
                <select
                  class="form-select"
                  v-model="plannerFields.state"
                  :disabled="isLoggedIn"
                  name="cust_state"
                  id="cust_state"
                  :required="!isLoggedIn"
                >
                  <option value="">Select a State</option>
                  <option value="AL">Alabama</option>
                  <option value="AK">Alaska</option>
                  <option value="AZ">Arizona</option>
                  <option value="AR">Arkansas</option>
                  <option value="CA">California</option>
                  <option value="CO">Colorado</option>
                  <option value="CT">Connecticut</option>
                  <option value="DE">Delaware</option>
                  <option value="DC">District Of Columbia</option>
                  <option value="FL">Florida</option>
                  <option value="GA">Georgia</option>
                  <option value="HI">Hawaii</option>
                  <option value="ID">Idaho</option>
                  <option value="IL">Illinois</option>
                  <option value="IN">Indiana</option>
                  <option value="IA">Iowa</option>
                  <option value="KS">Kansas</option>
                  <option value="KY">Kentucky</option>
                  <option value="LA">Louisiana</option>
                  <option value="ME">Maine</option>
                  <option value="MD">Maryland</option>
                  <option value="MA">Massachusetts</option>
                  <option value="MI">Michigan</option>
                  <option value="MN">Minnesota</option>
                  <option value="MS">Mississippi</option>
                  <option value="MO">Missouri</option>
                  <option value="MT">Montana</option>
                  <option value="NE">Nebraska</option>
                  <option value="NV">Nevada</option>
                  <option value="NH">New Hampshire</option>
                  <option value="NJ">New Jersey</option>
                  <option value="NM">New Mexico</option>
                  <option value="NY">New York</option>
                  <option value="NC">North Carolina</option>
                  <option value="ND">North Dakota</option>
                  <option value="OH">Ohio</option>
                  <option value="OK">Oklahoma</option>
                  <option value="OR">Oregon</option>
                  <option value="PA">Pennsylvania</option>
                  <option value="RI">Rhode Island</option>
                  <option value="SC">South Carolina</option>
                  <option value="SD">South Dakota</option>
                  <option value="TN">Tennessee</option>
                  <option value="TX">Texas</option>
                  <option value="UT">Utah</option>
                  <option value="VT">Vermont</option>
                  <option value="VA">Virginia</option>
                  <option value="WA">Washington</option>
                  <option value="WV">West Virginia</option>
                  <option value="WI">Wisconsin</option>
                  <option value="WY">Wyoming</option>
                </select>
                <span
                  class="text-danger"
                  v-if="plannerFields.errors && plannerFields.errors.state"
                  >Please select state.</span
                >
              </div>
            </div>
            <div v-bind:class="isLoggedIn ? 'row mb-4 d-none' : 'row mb-4'">
              <div class="col-md-4">
                <p>Zip Code:</p>
              </div>
              <div class="col-md-4">
                <input
                  type="text"
                  v-model="plannerFields.zip_code"
                  class="form-control"
                  :disabled="isLoggedIn && plannerFields.zip_code != null"
                  name="cust_zip_code"
                  id="cust_zip_code"
                  required=""
                />
                <span
                  class="text-danger"
                  v-if="plannerFields.errors && plannerFields.errors.zip_code"
                  >Please enter valid zipcode.</span
                >
              </div>
            </div>
            <div v-bind:class="isLoggedIn ? 'row mb-4 d-none' : 'row mb-4'">
              <div class="col-md-4">
                <p>Email:</p>
              </div>
              <div class="col-md-4">
                <input
                  type="email"
                  v-model="plannerFields.email"
                  class="form-control"
                  name="cust_email"
                  :disabled="isLoggedIn && plannerFields.email != null"
                  id="cust_email"
                  required=""
                />
                <span
                  class="text-danger"
                  v-if="plannerFields.errors && plannerFields.errors.email"
                  >Please enter valid email.</span
                >
              </div>
            </div>
            <div class="row mb-4">
              <div class="col-md-4">
                <p>Comments:</p>
              </div>
              <div class="col-md-4">
                <textarea
                  type="text"
                  id="comment"
                  name="comment"
                  v-model="plannerFields.comments"
                  rows="2"
                  maxlength="200"
                  class="form-control md-textarea h-auto"
                ></textarea>
              </div>
            </div>

            <div class="row mb-4">
              <div class="col-md-4">
                <p>Captcha:</p>
              </div>
              <div class="col-md-4 captcha_wrapper">
                <vue-recaptcha
                  v-if="showRecaptcha"
                  sitekey="6Le4FqEcAAAAANEKe-3kFDveux0h6aC3gOjBnLzJ"
                  size="normal"
                  theme="light"
                  :tabindex="0"
                  @verify="recaptchaVerified"
                  @expire="recaptchaExpired"
                  @fail="recaptchaFailed"
                  ref="vueRecaptcha"
                >
                </vue-recaptcha>
                <span id="error_msg_2" v-if="!isPageLoad && !isCaptchaVerified" style="color: #ff0000"> Captcha is required.</span>
              </div>
            </div>
            <input
              type="button"
              value="SUBMIT QUOTE"
              id="btfrom"
              class="btn btn-primary"
              @click="submitQuote"
            />

            <!--Grid row-->
          </form>
          <div
            id="page_content_below"
            class="mt-10 content-wrapper px-0 mt-5"
            v-if="!!getMetaDetails.description_below_dynamic"
            v-html="getMetaDetails.description_below_dynamic"
          ></div>
        </div>
      </div>
      <div class="container" v-else>
        Your quote list is empty. Visit the
        <a :href="'/flower-library'">Flower Library</a> to get started.
      </div>
    </section>
  </section>
</template>
<script>
import {
  FlashAlert,
  Modal,
  UpdateQuote,
  PageMetadata,
} from "@/components";
import { misc } from "@/mixins";
import axios from "axios";
import vueRecaptcha from "vue3-recaptcha2";
import { mapMutations } from "vuex";

export default {
  name: "QuoteList",
  mixins: [misc],
  components: {
    FlashAlert,
    vueRecaptcha,
    Modal,
    UpdateQuote,
    PageMetadata,
  },
  data() {
    return {
      showRecaptcha: true,
      deleteProductModalShow: false,
      productToDelete: "",
      plannerFields: {
        errors: {},
        delivery_date: "",
        customer_id: 1,
        company_name: "",
        name: "",
        last_name: "",
        email: "",
        phone: "",
        address: "",
        city: "",
        state: "",
        zip_code: "",
        comments: "",
      },
      isLoading: false,
      isSubmitEnable: false,
      quoteLists: [],
      programSettings: {},
      quoteListsCount: 0,
      isPageLoad: true,
      isCaptchaVerified: false,
      firstDate: "",
      availableDates: {},
      disabledDates: [],
      mixDate: '',
      maxDate: '',
    };
  },
  mounted() {
    localStorage.setItem("currentPage", "quote-list");
    if (this.isLoggedIn) {
      this.quoteLists = this.fetchQuoteList();
    } else {
      this.quoteLists = this.quoteDetails;
      this.quoteListsCount = Object.keys(this.quoteLists).length;
    }

    this.getProgramSettings();
    this.fetchMetaDetails();
    this.getUserDetails();
    this.emitter.on("updateQuote", (payload) => {
      this.updateQuoteAfterMath(payload);
      if (this.isLoggedIn) {
        this.quoteLists = this.fetchQuoteList();
      } else {
        this.quoteLists = this.quoteDetails;
      }
    });
  },
  unmounted() {
    this.emitter.off("updateQuote");
  },
  methods: {
    ...mapMutations(["removeFromQuote", "clearQuote"]),

    updateQuoteAfterMath(response) {
      this.showFlashAlert(response.msg, response.level);
    },

    fetchQuoteList() {
      let quoteListArray = {};
      axios
        .post(
          `${process.env.VUE_APP_API_BASE}auth/getPendingQuotes`,
          {},
          {
            headers: {
              "Content-type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then(({ data }) => {
          if (data.quote_date) {
            this.plannerFields.delivery_date = this.appendTimeForBrowser(data.quote_date);
          }
          data.products.forEach((prod) => {
            if (prod.product_id in quoteListArray) {
              quoteListArray[prod.product_id].quant += prod.quantity - 1;
            } else {
              quoteListArray[prod.product_id] = {
                quant: prod.quantity,
                quantityIncrement: prod.increment_quantity,
                prodId: prod.product_id,
                unitId: prod.unit_id,
                prodName: prod.name,
                prodImage: prod.image,
                prodCategoryName: prod.category_name,
                prodVarietyName: prod.variety_name,
                prodColorName: prod.color_name,
                prodGradeName: prod.grade_name,
                prodUnit: prod.unit,
                prodSeo: prod.seo_url,
                prodTypeId: prod.product_type_id,
              };
            }
          });

          this.quoteLists = quoteListArray;
          this.quoteListsCount = Object.keys(this.quoteLists).length;
          this.viewQuoteGA()
        });
    },

    hideDeleteProductModal() {
      this.productToDelete = "";
      this.deleteProductModalShow = false;
    },

    showDeleteProductModal(productId) {
      this.productToDelete = productId;
      this.deleteProductModalShow = true;
    },

    PPDeleted() {
      this.fetchSavedPP();
    },

    removeProduct() {
      this.emitter.emit("showLoader");
      if (this.isLoggedIn) {
        axios
          .post(
            `${process.env.VUE_APP_API_BASE}auth/deleteQuote`,
            {
              product_id: this.productToDelete,
            }, // the data to post
            {
              headers: {
                "Content-type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then(({ data }) => {
            let level = "error";
            if (data.success == true) {
              let productKey = this.productToDelete
              this.removeFromQuoteGA(this.quoteLists[productKey])
              this.removeFromQuote(this.productToDelete);
              this.fetchQuoteList();
              level = "success";
            }

            this.hideDeleteProductModal();
            this.showFlashAlert(data.message, level);
            this.emitter.emit("hideLoader");
          });
      } else {
        let level = "success";
        let message = "Product remove from quote successfully";
        this.removeFromQuote(this.productToDelete);
        level = "success";

        this.hideDeleteProductModal();
        this.showFlashAlert(message, level);
        this.emitter.emit("hideLoader");
      }
    },

    removeFromQuoteGA(prodObj) {
      let productObj = {
        event_category: "quote",
        event_label: 'remove from quote',
        item_id: prodObj.prodId,
        item_name: prodObj.prodName,
        item_category: prodObj.prodCategoryName,
        item_category2: prodObj.prodVarietyName,
        item_variant: prodObj.prodColorName,
        item_category3: prodObj.prodGrade,
        quantity: parseInt(prodObj.quant),
      };
      this.$gtag.event("remove_from_quote", productObj);
    },

    submitQuoteGA() {
      let quoteItemsKeys = Object.keys(this.quoteLists)
      const items = [];
      let prodObj = {}
      for (let counter = 0; counter < quoteItemsKeys.length; counter++) {
        prodObj = this.quoteLists[quoteItemsKeys[counter]]
          items.push({
            item_id: prodObj.prodId,
            item_name: prodObj.prodName,
            item_category: prodObj.prodCategoryName,
            item_category2: prodObj.prodVarietyName,
            item_variant: prodObj.prodColorName,
            item_category3: prodObj.prodGrade,
            quantity: parseInt(prodObj.quant),
          })
        
      }
      this.$gtag.event("submit_quote", {
        event_category: "quote",
        event_label: 'submit quote',
        items: items
      });
    },

    viewQuoteGA() {
      let quoteItemsKeys = Object.keys(this.quoteLists)
      const items = [];
      let prodObj = {}
      for (let counter = 0; counter < quoteItemsKeys.length; counter++) {
        prodObj = this.quoteLists[quoteItemsKeys[counter]]
          items.push({
            item_id: prodObj.prodId,
            item_name: prodObj.prodName,
            item_category: prodObj.prodCategoryName,
            item_category2: prodObj.prodVarietyName,
            item_variant: prodObj.prodColorName,
            item_category3: prodObj.prodGrade,
            quantity: parseInt(prodObj.quant),
          })
        
      }
      this.$gtag.event("view_quote", {
        event_category: "quote",
        event_label: 'view quote',
        items: items
      });
    },
    getProgramSettings() {
      axios
        .get(process.env.VUE_APP_API_BASE + "auth/getQuoteProgramSettings", {
          headers: {
            "Content-type": "application/json;multipart/form-data",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((response) => {
          if (response.data.error === false) {
            response.data.disabled_dates.forEach((disabledDate) => {
              const formatted = this.appendTimeForBrowser(disabledDate);
              this.disabledDates.push({
                start: formatted,
                end: formatted,
                date: disabledDate,
              });
            });
            this.minDate = this.appendTimeForBrowser(response.data.min_date);
            this.maxDate = this.appendTimeForBrowser(response.data.max_date);
            this.firstDate = this.minDate;
          }

          this.availableDates = response.data.available_dates;

          if (this.availableDates.length > 0 && 
            (this.plannerFields.delivery_date == '' || this.plannerFields.delivery_date == null)) {
            this.plannerFields.delivery_date = this.appendTimeForBrowser(this.availableDates[0]);
          }
        });
    },
    recaptchaVerified() {
      this.isSubmitEnable = true;
      this.isCaptchaVerified = true
    },
    recaptchaExpired() {
      this.$refs.vueRecaptcha.reset();
      this.isSubmitEnable = false;
      this.isCaptchaVerified = false
    },
    recaptchaFailed() {
      this.isSubmitEnable = false;
      this.isCaptchaVerified = false
    },
    validateForm() {
      let errorFlag = false;
      for (let plannerKey in this.plannerFields) {
        if (plannerKey == "delivery_date") {
          if (
            !this.plannerFields.delivery_date ||
            this.plannerFields.delivery_date == "" ||
            this.plannerFields.delivery_date == undefined
          ) {
            errorFlag = true;
            this.plannerFields.errors.deliveryDate = true;
          }
        }

        if (plannerKey == "email") {
          const emailRegex =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if (
            !emailRegex.test(
              String(this.plannerFields.email).toLowerCase() ||
                this.plannerFields.email == null
            )
          ) {
            errorFlag = true;
            this.plannerFields.errors.email = true;
          }
        }

        if (plannerKey == "name") {
          if (
            !this.plannerFields.name.trim() ||
            this.plannerFields.name.trim() == null
          ) {
            errorFlag = true;
            this.plannerFields.errors.name = true;
          }
        }

        if (plannerKey == "company_name") {
          if (
            !this.plannerFields.company_name.trim() ||
            this.plannerFields.company_name.trim() == null
          ) {
            errorFlag = true;
            this.plannerFields.errors.company_name = true;
          }
        }

        if (!this.isLoggedIn && plannerKey == "zip_code") {
          if (
            !this.plannerFields.zip_code.trim() ||
            this.plannerFields.zip_code.trim() == null
          ) {
            errorFlag = true;
            this.plannerFields.errors.zip_code = true;
          }
        }

        if (!this.isLoggedIn && plannerKey == "state") {
          if (
            !this.plannerFields.state.trim() ||
            this.plannerFields.state.trim() == null
          ) {
            errorFlag = true;
            this.plannerFields.errors.state = true;
          }
        }
      }
      return errorFlag;
    },

    getUserDetails() {
      if (this.isLoggedIn) {
        this.isLoading = true;
        try {
          axios
            .get(process.env.VUE_APP_API_BASE + "auth/miniprofile", {
              headers: {
                "Content-type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            })
            .then((response) => {
              let customerInfo = response.data.customer;
              this.plannerFields.name = customerInfo.first_name + " " + customerInfo.last_name;
              this.plannerFields.company_name = customerInfo.company_name;
              this.plannerFields.phone = customerInfo.phone;
              this.plannerFields.address = customerInfo.shipping_address;
              this.plannerFields.state = customerInfo.shipping_state;
              this.plannerFields.city = customerInfo.shipping_city;
              this.plannerFields.zip_code = customerInfo.shipping_zip_code;
              this.plannerFields.email = customerInfo.email;
              this.plannerFields.customer_id = customerInfo.id;
              this.isLoading = false;
            });
        } catch {
          this.isLoading = false;
        }
      }
    },

    submitQuote() {
      this.isPageLoad = false
      if (!this.validateForm() && this.isCaptchaVerified) {
        try {
          this.plannerFields.files = this.files;
          this.isLoading = true;
          axios
            .post(
              process.env.VUE_APP_API_BASE + "auth/submitQuote",
              { form_fields: this.plannerFields, products: this.quoteLists },
              {
                headers: {
                  "Content-type": "application/json;multipart/form-data",
                  Authorization: "Bearer " + localStorage.getItem("token"),
                },
              }
            )
            .then((response) => {
              if (response.data.error === true) {
                this.showFlashAlert(response.data.message, "error");
              } else {
                this.submitQuoteGA()
                this.clearQuote();
                this.showFlashAlert(response.data.message, "success");
                setTimeout(() => {
                  window.location.href = "/quote-thank-you";
                }, 2000);
              }
              this.isLoading = false;
            });
        } catch {
          this.isLoading = false;
        }
      } else {
        this.scrollToInvalidField();
      }
    },

    scrollToInvalidField() {
      // Without this setTimeout, an "undefined" error is thrown.
      setTimeout(() => {
        let el = this.$el.getElementsByClassName("text-danger")[0];
        el.scrollIntoView();
      }, 0);
    },
  },
  watch: {
    firstDate: function () {
      if (
        (
          this.plannerFields.delivery_date == "" ||
          this.plannerFields.delivery_date == undefined ||
          this.plannerFields.delivery_date < this.firstDate
        ) &&
        this.firstDate != ""
      ) {
        this.plannerFields.delivery_date = this.appendTimeForBrowser(this.firstDate);
      }
    },
  },
};
</script>
<style scoped>
.md-form {
  position: relative;
}

span.warn {
  position: absolute;
  /* float: right; */
  margin-top: -29px;
  margin-left: 458px;
}
.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}
.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle;
}
body .select2-container--default .select2-selection--single {
  border: 1px solid #ced4da;
  height: 38px;
}
.select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px;
}
.select2-container .select2-selection--single {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  height: 28px;
  user-select: none;
  -webkit-user-select: none;
}
body
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  line-height: normal;
  height: 38px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
body
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  line-height: normal;
  height: 38px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}
.event-planner {
  border: 2px solid #e6e5e5;
  margin-top: 22px;
  padding: 10px;
}
.mt_top {
  display: block;
  margin: 20px 15px 20px 0px;
}
.btn-success {
  margin-right: 5px;
}
.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.event-planner .table td:nth-child(4) {
  width: 8%;
}
.form-control {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  /* background-color: #fff; */
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.table thead th,
.table > :not(:last-child) > :last-child > * {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
  border-bottom-color: #dee2e6;
}
#btfrom {
  border: 2px solid #9f9f9f;
  border-radius: inherit;
  background: inherit;
  color: #797979;
  padding: 8px 25px;
}
#btfrom:hover {
  border: 2px solid #9f9f9f;
  background: #9f9f9f;
  color: #fff;
}
.red-asterick {
  color: red;
}
.error-text {
  scroll-margin: 50px 0 0 50px;
  color: red;
}
.unit_name {
  text-transform: capitalize;
}
.border-form {
  border: 1px solid #ccc;
}
.unit_name {
  text-transform: capitalize;
}
.popper {
  min-width: 280px !important;
  text-align: center;
}
:deep(.popper) {
  min-width: 281px;
  z-index: 9999;
  text-align: center;
}
.text-center td.text-left,
.text-center th.text-left {
  text-align: left;
  align-content: left;
  text-align-last: left !important;
}
.product-card .card-body .row>div{margin-bottom:0;}
.product-card .quantity, .product-card .lot-size,.product-card .action {
  justify-content: center;
  text-align:center;
}
.product-card .product-img-wrap{
  --col-size: 60px;
  flex-basis: var(--col-size);
  max-width: var(--col-size);
}
@media (min-width:768px){
  .product-card .product-img-wrap{
    text-align:center;
    --col-size: 134px;
  }
  .product-card .quantity {
    --col-size: 332px;
    flex-basis: var(--col-size);
    max-width: var(--col-size);
  }
  .product-card .lot-size {
    --col-size: 184px;
    flex-basis: var(--col-size);
    max-width: var(--col-size);
  }
  .product-card .action {
    --col-size: 158px;
    flex-basis: var(--col-size);
    max-width: var(--col-size);
  }
}
@media (max-width:991px){
  .product-card .quantity, .product-card .lot-size, .product-card .action{
    flex-basis: calc(100% / 3);
    max-width: calc(100% / 3);
  }
}
@media (max-width:767px){
  .product-card .quantity{
    --quantity-col: 160px;
      flex: 1 0 var(--quantity-col);
      max-width: var(--quantity-col);
      width:initial;
      justify-content: center;
  }
  .product-card .action {
    --action-col: 58px;
    flex: 1 0 var(--action-col);
    max-width: var(--action-col);
    flex-wrap: wrap;
    text-align:center;
  }
  .product-card .lot-size{
    --col-size: calc(100% - 58px - 160px);
    flex: 1 0 var(--col-size);
    max-width:var(--col-size);
  }
}
.product-wrapper:hover{
  box-shadow:none
}
</style>